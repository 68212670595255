<h2 mat-dialog-title>Confirm Delete</h2>
<mat-dialog-content>
    <p>
        Are you sure you want to delete this visit?
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    <button mat-button [mat-dialog-close]="true">Delete</button>
</mat-dialog-actions>
