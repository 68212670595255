<!-- <div> -->
  <!-- <nav class="navbar navbar-expand navbar-dark bg-dark">
    <a href="#" class="navbar-brand">Family First</a>
    <div class="navbar-nav mr-auto">
      <li class="nav-item">
        <a routerLink="tutorials" class="nav-link">Proctology</a>
      </li>
      <li class="nav-item">
        <a routerLink="add" class="nav-link">Schdule an enema</a>
      </li>
    </div>
  </nav> -->

  <!-- <div class="container mt-3"> -->
    <router-outlet></router-outlet>
  <!-- </div> -->
<!-- </div> -->