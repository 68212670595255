<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>Details</span>
        <span class="spacer"></span>
        <ng-container *ngIf="hasButtonBack">
            <button mat-raised-button type="button" aria-label="Back" (click)="handleBack()">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="hasButtonEdit">
            <button mat-raised-button type="button" aria-label="Edit" (click)="handleEdit()">
                <mat-icon>edit</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="hasButtonComplete">
            <button mat-raised-button type="button" aria-label="Complete" (click)="handleComplete()">
                <mat-icon>assignment_turned_in</mat-icon>
            </button>
        </ng-container>    
    </mat-toolbar-row>
</mat-toolbar>

<div class="field-container" *ngIf="patient">
    <div *ngIf="displayFields.patient_id">
        <label>Patient ID</label>
        <span>{{patient.patient_id}}</span>
    </div>
    <div>
        <label>Hospital</label>
        <span>{{patient.hospital.name}}</span>
    </div>
    <div *ngIf="displayFields.baby_first">
        <label>Baby's First</label>
        <span>{{patient.baby_first}}</span>
    </div>
    <div *ngIf="displayFields.baby_last">
        <label>Baby's Last</label>
        <span>{{patient.baby_last}}</span>
    </div>
    <div>
        <label>Baby's Birth Date</label>
        <span>{{patient.dob | date}}</span>
    </div>
    <div>
        <label>Baby's Sex</label>
        <span>{{patient.sex}}</span>
    </div>
    <div>
        <label>Status</label>
        <span>{{patient.current_status_name}}</span>
    </div>
    <div>
        <label>Status Date</label>
        <span>{{patient.current_status_date | date:'short' }}</span>
    </div>
    <div *ngIf="displayFields.mother_first">
        <label>Mother's First</label>
        <span>{{patient.mother_first}}</span>
    </div>
    <div *ngIf="displayFields.mother_last">
        <label>Mother's Last</label>
        <span>{{patient.mother_last}}</span>
    </div>
    <div *ngIf="displayFields.insurance">
        <label>Insurance</label>
        <span>{{patient.insurance}}</span>
    </div>
    <div *ngIf="displayFields.guarantor">
        <label>Guarantor</label>
        <span>{{patient.guarantor}}</span>
    </div>
    <div *ngIf="displayFields.has_ffp_visit">
        <label>Household Seen At FFP?</label>
        <span>{{ ( patient.has_ffp_visit ? 'Yes' : 'No' ) }}</span>
    </div>
    <div *ngIf="displayFields.has_future_ffp_visit">
        <label>Patient To Be Seen At FFP?</label>
        <span>{{ ( patient.has_future_ffp_visit ? 'Yes' : 'No' ) }}</span>
    </div>
    <div class="full-width">
        <label>CPT Codes (All Visits Aggregated)</label>
        <span>
            <mat-chip-list>
                <mat-chip *ngFor="let chip of previousVisitCptCodes; trackBy: trackByChip" selected [color]="cptColor" matTooltip="{{ chip.description }}" (click)="openSnackBar(chip.description)">
                    {{ chip.code }}
                </mat-chip>
            </mat-chip-list>
        </span>
    </div>
    <div class="full-width">
        <label>ICD-10 Codes (All Visits Aggregated)</label>
        <span>
            <mat-chip-list>
                <mat-chip *ngFor="let chip of previousVisitIcdCodes; trackBy: trackByChip" selected [color]="icdColor" matTooltip="{{ chip.description }}" (click)="openSnackBar(chip.description)">
                    {{ chip.code }}
                </mat-chip>
            </mat-chip-list>
        </span>
    </div>

    <div class="full-width">
        <label>Notes From All Visits (Aggregated)</label>
        <span>{{ allVisitNotes }}</span>
    </div>
</div>
