import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '@app/services/page-title.service';
import { AppComponent } from '@app/app.component';
import { Router, ActivatedRoute } from '@angular/router';
import { map, tap, first, take, switchMap, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services/http.service';
import { Patient } from '@app/models/patient';
import { Hospital } from '@app/models/hospital';
import { LoaderService } from '@app/services/loader.service';
import { Visit } from '@app/models/visit';
import { environment } from '@env/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-patient-view',
  templateUrl: './patient-view.component.html',
  styleUrls: ['./patient-view.component.css']
})
export class PatientViewComponent extends BaseComponent implements OnInit {

  visits: Visit[] = [];
  patientId: number = null;
  patient: Patient = null;
  urlBack: string = null;
  hospitals: Hospital[] = null;
  hospital: Hospital = null;
  chartImage: any = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appComponent: AppComponent,
    private httpService: HttpService,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
    private domSanitizer: DomSanitizer
  ) { 
    super();
  }

  ngOnInit(): void {
    this.appComponent.appendSiteTitle( 'Patient' );
    this.pageTitleService.setPageTitle( 'Patient' );
    this.loaderService.show();
    
    const promiseAll = this.activatedRoute.params.pipe(
      first( params => params.patient_id ),
      take(1),
      map( params => {
        this.patientId = params.patient_id;
        return params;
      }),
      switchMap( () => this.fetchPatient() ),
      switchMap( () => this.fetchVisits() ),
      switchMap( () => this.fetchChart() )
    );

    // run all promises using a pipe off of the activated route
    promiseAll
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      // do nothing
    }, error => console.error( error ), 
      () => {
        this.loaderService.hide();
    });

  }

  // what happens when the back button is clicked
  handleBack(): void {
    this.router.navigateByUrl( this.urlBack ); // navigate() takes in the same paramaters as url tree (see above)
  }

  handleComplete(): void {
    this.router.navigateByUrl( this.urlBack );
  }

  // upload chart cloud storage
  // https://stackoverflow.com/questions/48746467/upload-image-to-web-api-2-using-angular-5
  // handleAddChart( event: any ): void {

  //   this.loaderService.show();

  //   // get files from file input
  //   let files: FileList = event.target.files;
  //   let file = files.item(0);
  //   let formData: FormData = new FormData();

  //   // modify name of uploaded image before sending to api
  //   formData.append( 'chart', file, 'temp' );

  //   // this.loaderService.show();
  //   const urlChartAdd = this.httpService.createUrl([
  //     'api',
  //     'patients',
  //     this.patient.patient_id,
  //     'chart'
  //   ], 
  //     {},
  //     { host: environment.host_api_default }
  //   );

  //   let update = this.httpService.post( urlChartAdd, formData );
  //   update.subscribe( result => {
  //     this.fetchChart().subscribe( result => {
  //       this.loaderService.hide();
  //     });
  //   });
  // }

  // handle adding a new visit
  handleAddVisit(): void {
    const urlVisitAdd = this.router.createUrlTree([
      'patient',
      'visit',
      'add',
      this.patient.patient_id
    ]);
    this.router.navigateByUrl( urlVisitAdd );
  }

  // visit view button clicked
  handleViewVisit( visit: Visit ): void {
    const urlVisitAdd = this.router.createUrlTree([
      'patient',
      'visit',
      visit.visit_id
    ]);
    this.router.navigateByUrl( urlVisitAdd );
  }

  // patient edit button was clicked
  handleEdit(): void {
    const urlEdit = this.router.createUrlTree([
      'patient',
      'edit',
      this.patient.patient_id
    ]);
    this.router.navigateByUrl( urlEdit );
  }

  // fetch patient charts
  private fetchChart(): Observable<any> {
    const urlApiChart = this.httpService.createUrl([
      'api',
      'patients',
      this.patientId,
      'chart'
    ], {}, {
      host: environment.host_api_default
    });

    let chartObservable = this.httpService.get<any>( urlApiChart, {
      responseType: 'blob'
    }).pipe(
      tap( _ => null ),
      map(( result ) => {
        if( result.type === 'application/json' ) {
          // api returned json instead of image blob, this happens when the image is missing or some other error occurs
        } else {
          const blob = new Blob([result], { type: 'image/jpeg' });
          this.chartImage = this.domSanitizer.bypassSecurityTrustResourceUrl( window.URL.createObjectURL( blob));
          return result;
        }
      })
    );
    return chartObservable;
  }

  // get patient visits
  private fetchVisits(): Observable<any> {
    const urlApiVisit = this.httpService.createUrl([
      'api',
      'visits',
      'patient',
      this.patientId
    ], {
      queryParams: { include: [ 'codes', 'physician' ] }
    }, {
      host: environment.host_api_default
    });

    let visitObservable = this.httpService.get<Visit>( urlApiVisit )
      .pipe(
        tap( _ => null ),
        map(( result ) => {
          this.visits = result;
          return result;
        }),
        map(( result: Visit ) => result as Visit ),
      );
    return visitObservable;
  }

  // get patient data from api
  fetchPatient(): Observable<Patient> {

    // patient api endpoint
    let urlApiPatient: string = this.httpService.createUrl([ 
      'api', 
      'patients',
      this.patientId
    ],
      { 'queryParams': { include: ['hospital', 'current_status'] }},
      { host: environment.host_api_default }
    );

    let patientObservable = this.httpService.get<Patient>( urlApiPatient )
      .pipe(
        map(( result: Patient ) => result as Patient ),
        map(( result ) => {
          this.patient = result;
          this.urlBack = this.router.createUrlTree( ['patient','select', this.patient.hospital_id ] ).toString();
          return result;
        })
      );
    return patientObservable;
  }

}
