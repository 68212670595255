import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '@app/services/page-title.service';
import { AppComponent } from '@app/app.component';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { map, tap, first, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, concat } from 'rxjs';
import { HttpService } from '@app/services/http.service';
import { Patient } from '@app/models/patient';
import { Hospital } from '@app/models/hospital';
import { Status } from '@app/models/status';
import { LoaderService } from '@app/services/loader.service';
import { environment } from '@env/environment';
import { RouteDataService } from '@app/services/route-data.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.css']
})
export class PatientEditComponent extends BaseComponent implements OnInit {

  patientId: number = null;
  patient: Patient = null;
  urlBackDelete: UrlTree = null;
  urlBack: UrlTree = null;
  hospitals: Hospital[] = null;
  statuses: Status[] = null;

  constructor(
    public dialogDelete: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appComponent: AppComponent,
    private httpService: HttpService,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
    private routeDataService: RouteDataService,
    private snackBarService: MatSnackBar
  ) { 
    super();
  }
  
  ngOnInit(): void {
    this.appComponent.appendSiteTitle( 'Patient', 'Edit' );
    this.pageTitleService.setPageTitle( 'Patient Edit' );
    this.loaderService.show();
    
    // get data from api
    let promiseAll = this.activatedRoute.params.pipe(
      first( params => params.patient_id ),
      take(1),
      map( params => {
        this.patientId = params.patient_id;
        return params;
      }),
      switchMap( () => this.fetchPatient() )
    )

    let promiseRouteData = this.routeDataService.routeData$.pipe(
      first(( data ) =>  data.hospitals && data.statuses ),
      take(1),
      map( data => {
        this.statuses = data['statuses'].filter( status => status.status_slug === 'ACTIVE' ||  status.status_slug === 'COMPLETED' ) as Status[];
        this.hospitals = data['hospitals'] as Hospital[];
        return data;
      })
    );

    // https://rxjs.dev/deprecations/subscribe-arguments
    let promises = concat( promiseRouteData, promiseAll );
    promises
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe({
      next: ( r ) => {
        // handle results of each promise
      },
      error: ( e ) => {
        // handle errors
      },
      complete: () => {
        this.loaderService.hide(); 
      }
    });   
  }

  // what happens when the back button is clicked
  handleBack(): void {
    this.router.navigateByUrl( this.urlBack ); // navigate() takes in the same paramaters as url tree (see above)
  }

  // get data from api
  fetchPatient(): Observable<any> {

    // patient api endpoint
    let urlApiPatient: string = this.httpService.createUrl([ 
      'api', 
      'patients',
      this.patientId
    ],
      { 'queryParams': { include: [ 'hospital', 'current_status' ] } },
      { host: environment.host_api_default }
    );

    let promisePatient = this.httpService.get<Patient>( urlApiPatient )
      .pipe(
        tap( _ => null ),
        map(( result: Patient ) => result as Patient ),
        map(( result ) => { 
          this.patient = result;  
          this.urlBack = this.router.createUrlTree( ['patient','view', this.patient.patient_id ] );
          this.urlBackDelete = this.router.createUrlTree( ['patient','select', this.patient.hospital_id ] );
        }),
      );

    return promisePatient;
  }

  // when delete button is clicked
  handleButtonDelete(): void {
    const dialogReference = this.dialogDelete.open( PatientEditDialogDeleteComponent );
    dialogReference.afterClosed()
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe({
      next: ( r ) => {
        if( r )
        {
          this.handleDelete();
        }
      }
    }); 
  }

  // delete current visit
  private handleDelete(): void {

    let urlApiPatient: string = this.httpService.createUrl([ 
      'api', 
      'patients',
      this.patient.patient_id
    ],
      {},
      { host: environment.host_api_default }
    );

    this.httpService.delete( urlApiPatient )
    .pipe(
      tap( _ => { 
        this.loaderService.show();
        console.log( '...deleting patient' );
      })
    ).subscribe({
      next: () => {
        this.router.navigateByUrl( this.urlBackDelete );
      },
      error: ( e ) => {
        console.error( e )
      },
      complete: () => {
        this.loaderService.hide();
      }
    });
  }

  // update patient details via api
  onSubmit( form: object ): void {

    this.loaderService.show();
    let urlApiPatient: string = this.httpService.createUrl([ 
      'api', 
      'patients',
      this.patient.patient_id
    ], 
      {},
      { host: environment.host_api_default }
    );
    let update = this.httpService.put<Patient>( urlApiPatient, form );
    update.subscribe({
      next: ( r ) => {
        this.router.navigateByUrl( this.urlBack );
      },
      error: ( e ) => {
        this.loaderService.hide();
        this.snackBarService.open(e.statusText, `OK`, {
          duration: 3000
        });
      },
      complete: () => {
        this.loaderService.hide();
      }
    });

  }

}



// dialog specific to this component
@Component({
  selector: 'app-patient-edit-dialog-delete',
  templateUrl: 'patient-edit-dialog-delete.html'
})
export class PatientEditDialogDeleteComponent {
}