import { Component, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

@Component({
    templateUrl: './base.component.html'
})
export class BaseComponent implements OnDestroy{
    appUnsubscribe = new Subject<void>();

    ngOnDestroy(): void {
        this.appUnsubscribe.next();
        this.appUnsubscribe.complete();
    }
}