import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '@app/services/page-title.service';
import { AppComponent } from '@app/app.component';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { map, tap, first, take, switchMap, takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services/http.service';
import { LoaderService } from '@app/services/loader.service';
import { Visit } from '@app/models/visit';
import { Code } from '@app/models/code';
import { environment } from '@env/environment';
import { AuthService } from '@app/services/auth.service';
import { User } from '@app/models/user';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemePalette } from '@angular/material/core';
import { BaseComponent } from '@app/base.component';


@Component({
  selector: 'app-patient-visit-view',
  templateUrl: './patient-visit-view.component.html',
  styleUrls: ['./patient-visit-view.component.css']
})
export class PatientVisitViewComponent extends BaseComponent implements OnInit {

  private user: User = null;
  visitId: number = null;
  visit: Visit = null;
  urlBack: UrlTree = null;
  cptColor: ThemePalette = "warn";
  icdColor: ThemePalette = "accent";


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appComponent: AppComponent,
    private httpService: HttpService,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
    private AuthService: AuthService,
    private snackBar: MatSnackBar
  ) { 
    super();
  }

  ngOnInit(): void {
    this.appComponent.appendSiteTitle( 'Patient', 'Visit', 'View' );
    this.pageTitleService.setPageTitle( 'View Visit' );
    this.loaderService.show();
    this.user = this.AuthService.getCurrentUser();    

    const promiseAll = this.activatedRoute.params.pipe(
      first( params => params.visit_id ),
      take(1),
      map( params => {
        this.visitId = params.visit_id;
        return params;
      }),
      switchMap( () => this.fetchVisit() )
    );

    // run all promises using a pipe off of the activated route
    promiseAll
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      // get the back url
      this.urlBack = this.router.createUrlTree([ 
        'patient', 
        'view', 
        this.visit.patient.patient_id 
      ]);
    }, error => console.error( error ), 
      () => {
        this.loaderService.hide();
    });
  }

  // what happens when the back button is clicked
  handleButtonBack( event: Visit ): void {
    this.router.navigateByUrl( this.urlBack ); // navigate() takes in the same paramaters as url tree (see above)
  }

  // patient edit button was clicked
  handleButtonEdit( event: Visit ): void {
    const urlEdit = this.router.createUrlTree([
      'patient',
      'visit',
      'edit',
      this.visit.visit_id
    ]);
    this.router.navigateByUrl( urlEdit );
  }

  // get patient visits
  fetchVisit(): Observable<any> {
    const urlApiVisit = this.httpService.createUrl([
      'api',
      'visits',
      this.visitId
    ], {
      queryParams: { include: [ 'patient', 'codes', 'physician' ] }
    }, {
      host: environment.host_api_default
    });

    let visitObservable = this.httpService.get<Visit>( urlApiVisit )
      .pipe(
        tap( _ => console.log( '...loading visit data' )),
        map(( result: Visit ) => result as Visit ),
        map(( result ) => {
          this.visit = result;
          return result;
        }),
      );
    return visitObservable;
  }

  // filter codes by type
  filterCodesByType( codes: Code[], type: string )
  { 
    return codes.filter( code => code.code_type == type );
  }

  trackByChip(  index: number, item: any ): string {
    return item.code;
  } 

  openSnackBar(toolTip: string) {
    this.snackBar.open(toolTip, 'Ok', {
      duration: 3000
    });
  }

}

