<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>Details</span>
        <span class="spacer"></span>
        <ng-container *ngIf="hasButtonBack">
            <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="hasButtonEdit">
            <button mat-raised-button type="button" aria-label="Edit" (click)="handleEdit()">
                <mat-icon>edit</mat-icon>
            </button>
        </ng-container>
    </mat-toolbar-row>
</mat-toolbar>

<div class="field-container" *ngIf="visit">
    <div>
        <label>Provider</label>
        <span>{{visit.physician.person.last_name}}, {{visit.physician.person.first_name}}</span>
    </div>
    <div *ngIf="displayFields.created_at">
        <label>Visit Entered</label>
        <span>{{visit.created_at | date: 'MMM dd, yyyy HH:mm' }}</span>
    </div>
    <div>
        <label>Visit Date</label>
        <span>{{visit.visit_date | date: 'MMM dd, yyyy' }}</span>
    </div>
    <!--
    <div>
        <label>Mother First</label>
        <span>{{visit.patient.mother_first}}</span>
    </div>
    <div>
        <label>Mother Last</label>
        <span>{{visit.patient.mother_last}}</span>
    </div>
    <div>
        <label>Baby's First</label>
        <span>{{visit.patient.baby_first}}</span>
    </div>
    -->
    <div>
        <label>Baby's Last</label>
        <span>{{visit.patient.baby_last}}</span>
    </div>
    <div>
        <label>Visit ID</label>
        <span>{{visit.visit_id}}</span>
    </div>
    <div class="full-width">
        <label>CPT Codes</label>
        <span>
            <mat-chip-list>
                <mat-chip *ngFor="let chip of filterCodesByType( visit.codes, 'CPT' ); trackBy: trackByChip" selected [color]="cptColor" matTooltip="{{ chip.description }}" (click)="openSnackBar(chip.description)">
                    {{ chip.code }}
                </mat-chip>
            </mat-chip-list>
        </span>
    </div>
    <div class="full-width">
        <label>ICD-10 Codes</label>
        <span>
            <mat-chip-list>
                <mat-chip *ngFor="let chip of filterCodesByType( visit.codes, 'ICD-10' ); trackBy: trackByChip" selected [color]="icdColor" matTooltip="{{ chip.description }}" (click)="openSnackBar(chip.description)">
                    {{ chip.code }}
                </mat-chip>
            </mat-chip-list>
        </span>
    </div>
    <div class="full-width">
        <label>Notes</label>
        <span>{{visit.notes}}</span>
    </div>
</div>