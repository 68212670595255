<mat-toolbar color="accent">
    <mat-toolbar-row>
      <span>
        {{ subTitle }}
      </span>
      <span class="spacer"></span>
      <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button mat-raised-button type="button" aria-label="Edit" (click)="handleEdit()">
        <mat-icon>edit</mat-icon>
    </button>
    </mat-toolbar-row>
</mat-toolbar>

<div class="field-container" *ngIf="isLoaded">
    <div>
        <label>First Name</label>
        <span>{{person.first_name}}</span>
    </div>
    <div>
        <label>Last Name</label>
        <span>{{person.last_name}}</span>
    </div>
    <div>
        <label>Title</label>
        <span>{{person.physician.title }}</span>
    </div>
    <div>
        <label>Preferred Name</label>
        <span>{{person.preferred_name }}</span>
    </div>
    <div>
        <label>Email</label>
        <span>{{person.user.email}}</span>
    </div>
    <div>
        <label>Phone</label>
        <span>{{person.phone}}</span>
    </div>
    <div>
        <label>Username</label>
        <span>{{person.user.username}}</span>
    </div>
    <div>
        <label>Password Change Required</label>
        <span>{{ ( person.user.has_password_change ? 'Yes' : 'No' ) }}</span>
    </div>
    <div>
        <label>Application Role</label>
        <span>
            <ng-container *ngFor="let role of person.user.roles">
                <span class="role-container">{{ role.role_name }}</span>
            </ng-container>
        </span>
    </div>
</div>