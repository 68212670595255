import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { AppComponent } from '@app/app.component';
import { User } from '@app/models/user';
import { HttpService } from '@app/services/http.service';
import { LoaderService } from '@app/services/loader.service';
import { PageTitleService } from '@app/services/page-title.service';
import { environment } from '@env/environment';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.css']
})
export class UserSelectComponent extends BaseComponent implements OnInit {

  // local variables
  userData: MatTableDataSource<User> = new MatTableDataSource<User>([]);
  urlBack: string = null;
  
  // viewchild objects
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;
  
  columnsToDisplay: string[] = ['edit', 'user_name', 'email', 'phone', 'roles'];
  themeColor: ThemePalette = 'primary';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe( Breakpoints.Handset )
    .pipe(
      map( result => result.matches ),
      shareReplay()
    );

  constructor(
    private pageTitleService: PageTitleService,
    private appComponent: AppComponent,
    private router: Router,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.isHandset$
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      if (result) {
        this.columnsToDisplay = ['edit', 'user_name', 'roles'];
      } else {
        this.columnsToDisplay = ['edit', 'user_name', 'email', 'phone', 'roles', 'visits'];
      }
    });
    this.loaderService.show();

    this.appComponent.appendSiteTitle('User', 'Select');
    this.pageTitleService.setPageTitle('Users Administration');

    this.urlBack = this.router.createUrlTree([ '/' ]).toString();
    this.fetchUsers();
  }

  handleBack(): void {
    this.router.navigateByUrl( this.urlBack );
  }

  handleUserAdd(): void {
    this.router.navigate(['administration', 'register']);
  }

  handleRowEdit(user: User): void {
    this.router.navigate(['administration', 'users', user.person_id]);
  }

  private mapPersonToUser(person: any): User {
    let user = new User();
    user.person_id = person.person_id;
    user.first_name = person.first_name;
    user.last_name = person.last_name;
    user.preferred_name = person.preferred_name;
    user.phone = person.phone;
    if (person.physician) {
      user.physician_id = person.physician.physician_id;
      user.title = person.physician.title;
      if( person.physician.visits ) {
        user.visits = person.physician.visits;
      }
    }
    if (person.user) {
      user.user_id = person.user.user_id;
      user.email = person.user.email;
      user.roles = person.user.roles;
    }
    return user;
  }

  fetchUsers(): void {
    let urlApi: string = this.httpService.createUrl([
      'api',
      'users'
    ], {}, { host: environment.host_api_default });

    this.httpService.get<User[]>(urlApi).pipe(
      tap( _ => null )
    ).subscribe(data => {
      let users: User[] = [];
      data.forEach(person => {
        users.push(this.mapPersonToUser(person));
      });
      this.userData.data = users;
    }, () => {
      // do nothing
    }, () => {
      // console.log(JSON.stringify(this.userData.data[0]));
      this.loaderService.hide();
    });
  }
  
}
