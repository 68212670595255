import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Visit } from '@app/models/visit';
import { Code } from '@app/models/code';
import { Field } from '@app/models/field';
import { Observable } from 'rxjs';
import { HttpService } from '@app/services/http.service';
import { environment } from '@env/environment';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-patient-visit-detail',
  templateUrl: './patient-visit-detail.component.html',
  styleUrls: ['./patient-visit-detail.component.css']
})
export class PatientVisitDetailComponent implements OnInit {

  cptColor: ThemePalette = "warn";
  icdColor: ThemePalette = "accent";
  fields: Field[] = [];
  displayFields: any = {
    'created_at': true
  };

  @Input('visit') visit: Visit;
  @Input('hasButtonEdit') hasButtonEdit: Boolean = false;
  @Output('onButtonEdit') onButtonEdit: EventEmitter<Visit> = new EventEmitter<Visit>(); 
  @Input('hasButtonBack') hasButtonBack: Boolean = false;
  @Output('onButtonBack') onButtonBack: EventEmitter<Visit> = new EventEmitter<Visit>();
  @Input('filterFields') filterFields: Boolean = false;

  constructor(
    private httpService: HttpService,
    private snackBar: MatSnackBar
  ) { 
  }

  ngOnInit(): void {
    // get active form fields
    this.fetchFields().subscribe( result => {
      if (this.filterFields ) {
        this.fields.forEach( field => {
          if ( this.displayFields.hasOwnProperty( field['field_name'] ) ) {
            this.displayFields[ field['field_name'] ] = field['display_field'];
          }
        });
      }
    });
  }

  // fetch active/inactive fields
  private fetchFields(): Observable<Field[]> {
    const urlApiFields = this.httpService.createUrl([
      'api',
      'fields'
    ], {}, {
      host: environment.host_api_default
    });

    let fieldObservable = this.httpService.get<Field[]>( urlApiFields, {} ).pipe(
      tap( _ => null ),
      map(( result: Field[] ) => result as Field[] ),
      map(( result ) => {
        this.fields = result;
        return result;
      })
    );
    return fieldObservable;
  }

  // patient edit button was clicked
  handleEdit(): void {
    this.onButtonEdit.emit( this.visit );
  }

  // when back button is clicked
  handleBack(): void {
    this.onButtonBack.emit( this.visit );
  }

  // filter codes by type
  filterCodesByType( codes: Code[], type: string )
  { 
    return codes.filter( code => code.code_type == type );
  }

  trackByChip(  index: number, item: any ): string {
    return item.code;
  } 

  openSnackBar(toolTip: string) {
    this.snackBar.open(toolTip, 'Ok', {
      duration: 3000
    });
  }

}
