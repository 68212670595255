<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>Visit</span>
        <span class="spacer"></span>
        <span>
            <button type="button" aria-label="Go Back" mat-raised-button (click)="handleBack()">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </span>
        <span>
            <button type="button" aria-label="Go Back" color="warn" mat-raised-button (click)="handleButtonDelete()">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </span>
    </mat-toolbar-row>
</mat-toolbar>

<app-patient-visit-form 
    (onSubmit)="handleSubmit( $event )" 
    [canComplete]='true'
    [visit]="visit" 
    [visits]="visits"
    [providers]="physicians"
    [previousVisitCptCodes]="previousVisitCptCodes"
    [previousVisitIcdCodes]="previousVisitIcdCodes"
    [allCptCodes]="allCptCodes" 
    [allIcdCodes]="allIcdCodes">
</app-patient-visit-form>

