import { Component, Input, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Hospital } from '@app/models/hospital';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ThemePalette } from '@angular/material/core';
import { Patient } from '@app/models/patient';
import { Status } from '@app/models/status';
import { MatTableDataSource } from '@angular/material/table';
import { LoaderService } from '@app/services/loader.service';

@Component({
  selector: 'app-hospital-patient-list',
  templateUrl: './hospital-patient-list.component.html',
  styleUrls: ['./hospital-patient-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', 
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class HospitalPatientListComponent implements OnInit {

  // local variables
  patientData: MatTableDataSource<Patient> = new MatTableDataSource<Patient>([]);
  selectedStatus: Status = new Status();
  patients: Patient[] = [];

  // @Input() onRowEdit: Function;
  @Input('dataSource') dataSource: Hospital;
  @Input('statuses') statuses: Status[] = [];
  @Input('hasData') hasData: boolean = false;
  @Input('displayMother') displayMother: boolean = false;
  @Input('displayChild') displayChild: boolean = true;
  @Output('onRowEdit') onRowEdit: EventEmitter<Patient> = new EventEmitter<Patient>();
  @Output('onRowView') onRowView: EventEmitter<Patient> = new EventEmitter<Patient>();
  @Output('onBack') onBack: EventEmitter<any> = new EventEmitter<any>();
  @Output('onPatientAdd') onPatientAdd: EventEmitter<Hospital> = new EventEmitter<Hospital>();

  columnsToDisplay: string[] = ['view', 'current_status_name', 'dob', 'sex' ];
  expandedElement: any | null;
  themeColor: ThemePalette = "primary";

  // link to child elements
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private loaderService: LoaderService
  ) {
    this.dataSource = new Hospital();
    this.dataSource.patients = [];
  }

  ngOnInit(): void {
    if( this.displayMother == true ){ this.columnsToDisplay.splice(1, 0, 'mother_last'); }
    if( this.displayChild == true ){ this.columnsToDisplay.splice(1, 0, 'baby_last'); }
  }

  // when input/output data changes
  ngOnChanges(): void {
    if( this.dataSource.patients.length > 0 )
    {
      if( this.statuses.length > 0 )
      {
        // set the selected status
        this.selectedStatus = this.statuses[0];
        this.filterPatientData();
      }
    }

  }

  // filter patient data
  private filterPatientData(): void {
    this.loaderService.show();
    let patients = this.dataSource.patients;
    patients = patients.filter( patient => {
      return patient.current_status_id == this.selectedStatus.status_id;
    });
    this.patientData = new MatTableDataSource<Patient>( patients );
    this.patientData.sort = this.sort;
    this.loaderService.hide();
  }

  // when status drop-down changes
  onChangeStatus( event: any ): void {
    this.selectedStatus = event.value;
    this.filterPatientData();
  }

  // what happens when back button is clicked
  handleBack(): void {
    this.onBack.emit();
  }

  handlePatientAdd(): void {
    this.onPatientAdd.emit( this.dataSource );
  }

  // action when edit button is clicked
  handleRowEdit( element: Patient ): void {
    this.onRowEdit.emit( element );
  }

  // what to do when view button is clicked on
  handleRowView( element: Patient ): void {
    this.onRowView.emit( element );
  }

  rowExpand( element: any ) {
    element.isExpanded = true;
    if (this.expandedElement === element) {
      element.isExpanded = false;
      this.expandedElement = null;
    } else {
      if (this.expandedElement)
        this.expandedElement.isExpanded = false;
      this.expandedElement = element;
    }
  }
}
