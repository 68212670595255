<mat-toolbar color="accent">
  <mat-toolbar-row>
    <span>
      {{ dataSource.short_name }}
    </span>
    
    <span class="spacer"></span>
    <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
      <mat-icon>chevron_left</mat-icon>
    </button>

    <button type="button" aria-label="Go Back" mat-raised-button (click)="handlePatientAdd()">
      <mat-icon>add</mat-icon>
    </button>

    <mat-select [(value)]="selectedStatus" (selectionChange)="onChangeStatus( $event )" class="mat-select-box">
        <mat-option *ngFor="let status of statuses" [value]="status">{{ status.status_name }}</mat-option>
    </mat-select>
  </mat-toolbar-row>
</mat-toolbar>
<table mat-table [dataSource]="patientData" matSortDirection="asc" matSortActive="desc" matSort multiTemplateDataRows class="mat-elevation-z8">

  <ng-container matColumnDef="view">
    <th mat-header-cell class="mat-header-text" *matHeaderCellDef>View</th>
    <td mat-cell class="mat-cell-button" *matCellDef="let element">
      <button mat-mini-fab color="white" aria-label="Example icon button with a menu icon"
        [class.example-expanded-row]="expandedElement === element">
        <!-- <mat-icon>{{ element.isExpanded ? 'visibility_off' : 'visibility' }}</mat-icon> -->
        <mat-icon>visibility</mat-icon>
      </button>
    </td>
  </ng-container>
  
  <!--
  <ng-container matColumnDef="edit">
    <th mat-header-cell class="mat-header-text" *matHeaderCellDef>Edit</th>
    <td mat-cell class="mat-cell-button" *matCellDef="let element">
      <button mat-mini-fab color="white" aria-label="Example icon button with a menu icon"
        (click)="handleRowEdit( element )">
        <mat-icon>edit</mat-icon>
      </button>
    </td>
  </ng-container>
  -->

  <ng-container matColumnDef="mother_last">
    <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Mother</th>
    <td mat-cell class="mat-cell-name" *matCellDef="let element"> {{ ( element['mother_last'] ? element['mother_last'] : '' ) +
      ( element['mother_first'] ? ', ' + element['mother_first'] : '' ) }}</td>
  </ng-container>

  <ng-container matColumnDef="baby_last">
    <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Child</th>
    <td mat-cell class="mat-cell-name" *matCellDef="let element"> {{ ( element['baby_last'] ? element['baby_last'] : '' ) +
      ( element['baby_first'] ? ', ' + element['baby_first'] : '' ) }}</td>
  </ng-container>

  <ng-container matColumnDef="current_status_name">
    <th mat-header-cell class="mat-cell-status" *matHeaderCellDef mat-sort-header>Status</th>
    <td mat-cell class="mat-cell-status" *matCellDef="let element"> {{ element.current_status_name }}</td>
  </ng-container>

  <ng-container matColumnDef="dob">
    <th mat-header-cell class="mat-header-text mat-cell-dob" *matHeaderCellDef mat-sort-header>Date of Birth</th>
    <td mat-cell class="mat-cell-text mat-cell-dob" *matCellDef="let element"> {{ element['dob'] | date }}</td>
  </ng-container>

  <ng-container matColumnDef="sex">
    <th mat-header-cell class="mat-header-text mat-cell-sex" *matHeaderCellDef mat-sort-header>Sex</th>
    <td mat-cell class="mat-cell-text mat-cell-sex" *matCellDef="let element"> {{ element['sex'] }}</td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
        <!-- <div class="example-element-detail" [@detailExpand]="'expanded'"> -->
        <div class="example-element-description">
          <div class="detail-column-1">
            <mat-form-field class="field-layout">
              <mat-label>Insurance</mat-label>
              <input matInput value="{{ element.insurance }}">
            </mat-form-field>
            <mat-form-field class="field-layout">
              <mat-label>Guarantor</mat-label>
              <input matInput value="{{ element.guarantor }}">
            </mat-form-field>
          </div>
          <!-- <mat-divider vertical class="detail-divider"></mat-divider> -->
          <div class="detail-column-2">
            <mat-slide-toggle class="slider-toggle" [color]="themeColor" [checked]="element.has_ffp_visit">
              Already Seen at FFP
            </mat-slide-toggle>
            <mat-slide-toggle class="slider-toggle" [color]="themeColor" [checked]="element.has_future_ffp_visit">
              To Be Seen at FFP
            </mat-slide-toggle>
          </div>
        </div>
        <ng-container *ngIf="element.visits && element.visits.length">
          <div class="example-element-description">
            <app-patient-visit-list style="width: 100%;" [visits]="element.visits"></app-patient-visit-list>
          </div>
        </ng-container>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <!-- <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" (click)="rowExpand( element )"> -->
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" (click)="handleRowView( element )"></tr>
  <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
</table>