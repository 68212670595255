import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Visit } from '@app/models/visit';
import { Code } from '@app/models/code';
import { Observable } from 'rxjs';
import { map, tap, startWith, takeUntil } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';
import { BaseComponent } from '@app/base.component';
import { Physician } from '@app/models/physician';

@Component({
  selector: 'app-patient-visit-form',
  templateUrl: './patient-visit-form.component.html',
  styleUrls: ['./patient-visit-form.component.css']
})
export class PatientVisitFormComponent extends BaseComponent implements OnInit, OnChanges {

  // class variables
  private isSubmitDisabled: boolean = true;
  readonly separatorKeyCodes: number[] = [ENTER, COMMA];
  allVisitNotes: string = '';
  filteredCptCodes: Observable<Code[] | any>;
  filteredIcdCodes: Observable<Code[]>;
  inputCpt = new UntypedFormControl( null );
  inputIcd = new UntypedFormControl( null );
  cptColor: ThemePalette = "warn";
  icdColor: ThemePalette = "accent";

  // visit form
  visitForm = new UntypedFormGroup({
    visit_date: new UntypedFormControl((new Date()).toISOString(), Validators.required ),
    cpt_codes: new UntypedFormControl( null ),
    physician_id: new UntypedFormControl( null, { validators: Validators.required }),
    icd_codes: new UntypedFormControl( null ),
    notes: new UntypedFormControl( null )
  });

  // inputs/outputs
  @Input() initialValues: object = {};
  @Input("visit") visit: Visit = null;
  @Input("visits") visits: Visit[] = []; // other visits associated with the same patient
  @Input("providers") providers: Physician[] = []; 
  @Input("provider") provider: Physician = null; 
  @Input() allCptCodes: Code[] = [];
  @Input() allIcdCodes: Code[] = [];
  @Input() previousVisitIcdCodes: Code[] = [];
  @Input() previousVisitCptCodes: Code[] = [];
  @Input() icdCodes: Code[] = [];
  @Input() cptCodes: Code[] = [];
  @Input() canDelete: boolean = false;
  @Input() canComplete: boolean = false;
  @Output() onSubmit: EventEmitter<{ visit: Visit, complete: boolean }> = new EventEmitter<{ visit: Visit, complete: boolean }>();
  @Output() onRemoveCptCode: EventEmitter<Code> = new EventEmitter<Code>();
  @Output() onRemoveIcdCode: EventEmitter<Code> = new EventEmitter<Code>();
  @ViewChild('cpt_codes') inputCptCodes: ElementRef<HTMLInputElement>;
  @ViewChild('icd_codes') inputIcdCodes: ElementRef<HTMLInputElement>;

  // constructor
  constructor(
    private snackBar: MatSnackBar
  ) { 
    super();
    // set hospital if one is passed to this component
    if( Object.keys( this.initialValues ).length !== 0 )
    {
      this.visitForm.patchValue( this.initialValues );
    }
  }
  
  // used to initialize visit values
  initializeVisit() {
    // split existing codes into cpt and icd codes
    if( this.visit.codes.length > 0 )
    {
      this.icdCodes = this.visit.codes.filter( code => code.code_type === 'ICD-10' );
      this.icdCodes.forEach( code => {
        this.removeFromAllIcdCodes( code );
      });
      this.cptCodes = this.visit.codes.filter( code => code.code_type === 'CPT' );
      this.cptCodes.forEach( code => {
        this.removeFromAllCptCodes( code );
      });
    }

    this.visitForm.patchValue({
      physician_id: this.visit.physician_id,
      notes: this.visit.notes,
      visit_date: this.visit.visit_date
    });
  }

  // when cpt codes are removed from form
  handleRemoveCptCode( code: Code ): void {
    const index = this.cptCodes.findIndex( c => c.code_id === code.code_id );
    if( index >= 0 ) {
      this.cptCodes.splice( index, 1 );
    }
    this.allCptCodes.push( code );
    this.allCptCodes.sort( Code.sortCodesByCode );          
    this.inputCpt.setValue( null );
    this.inputCptCodes.nativeElement.value = '';
    this.inputCptCodes.nativeElement.blur();

    this.onRemoveCptCode.emit( code );
  }

  // when icd codes are removed from form
  handleRemoveIcdCode( code: Code ): void {
    const index = this.icdCodes.findIndex( c => c.code_id === code.code_id );
    if( index >= 0 ) {
      this.icdCodes.splice( index, 1 );
    }
    this.allIcdCodes.push( code );
    this.allIcdCodes.sort( Code.sortCodesByCode );
    this.inputIcd.setValue( null );
    this.inputIcdCodes.nativeElement.value = '';
    this.inputIcdCodes.nativeElement.blur();

    this.onRemoveIcdCode.emit( code );
  }

  // remove from all cpt codes
  private removeFromAllCptCodes( code: Code )
  {
    const index = this.allCptCodes.findIndex( c => c.code_id === code.code_id );
    this.allCptCodes.splice( index, 1 );
  }

  // remove from all cpt codes
  private removeFromAllIcdCodes( code: Code )
  {
    const index = this.allIcdCodes.findIndex( c => c.code_id === code.code_id );
    this.allIcdCodes.splice( index, 1 );
  }

  // what to do when cpt codes are selected
  cptSelected( event: MatAutocompleteSelectedEvent ): void {
    const index = this.allCptCodes.findIndex( code => code.code_id === event.option.value );
    const code = this.allCptCodes[index];
    this.cptCodes.push( code );
    this.removeFromAllCptCodes( code );

    this.inputCpt.setValue( null );
    this.inputCptCodes.nativeElement.value = '';
    this.inputCptCodes.nativeElement.blur();
  }
  
  // what to do when cpt codes are selected
  icdSelected( event: MatAutocompleteSelectedEvent ): void {
    const index = this.allIcdCodes.findIndex( code => code.code_id === event.option.value );
    let code = this.allIcdCodes[index]
    this.icdCodes.push( code );
    this.removeFromAllIcdCodes( code );
    
    this.inputIcd.setValue( null );
    this.inputIcdCodes.nativeElement.value = '';
    this.inputIcdCodes.nativeElement.blur();
  }

  // when input data changes, can happen frequently
  ngOnChanges( changes: SimpleChanges ): void {

    // check for changes in the cpt codes
    if( this.allCptCodes )
    {
      // this.allCptCodes.sort( Code.sortCodesByCode );          
      this.filteredCptCodes = this.inputCpt.valueChanges.pipe(
        startWith( <string>null ),
        tap( _ => null ), // you can look at values through the tap function
        map(( text: string | null ) => ( text ? this.filterCodes( text, 'cpt' ) : this.allCptCodes.slice()))
      ); 
    }

    // check for changes in the icd codes
    if( this.allIcdCodes )
    {
      // this.allIcdCodes.sort( Code.sortCodesByCode );          
      this.filteredIcdCodes = this.inputIcd.valueChanges.pipe(
        startWith( <string>null ),
        tap( _ => null ), // you can look at values through the tap function
        map(( text: string | null ) => ( text ? this.filterCodes( text, 'icd' ) : this.allIcdCodes.slice()))
      );     
    }

    // setup visit
    if( changes.visit && changes.visit.currentValue )
    {
      this.initializeVisit();
    }

    // sets the default provider
    if( this.providers.length > 0 && this.provider )
    {
      this.visitForm.patchValue({
        'physician_id': this.provider.physician_id
      });
    }

    // extract data from existing visits
    if( changes.visits )
    {
      if( this.visits ){
        this.allVisitNotes = Visit.aggregateVisitNotes( this.visits );
      }
    }

  }

  // handle whether or not submit button should be active
  getIsSubmitActive(): boolean {
    return this.isSubmitDisabled;
  }

  // handle form submit
  handleSubmit( buttonName ): void {

    let emit = {
      visit: this.visitForm.value,
      complete: false
    }
    emit.visit['cpt_codes'] = this.cptCodes; // add selected cpt codes
    emit.visit['icd_codes'] = this.icdCodes; // add selected icd codes
    
    // check to see if user clicked patient close
    if( buttonName === 'complete'){
      emit.complete =  true;
    }
    this.onSubmit.emit( emit );
  }

  ngOnInit(): void {
    // check to see if patient has been passed to this form
    // use patchValue to update a single
    this.visitForm.patchValue({
      visit_date: new Date().toISOString()
    });

    // monitor form changes
    this.isSubmitDisabled = !this.visitForm.valid;
    this.visitForm.valueChanges
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( data => {
      this.isSubmitDisabled = !this.visitForm.valid; // set form validity whenever values change
    }); 
  }

  // filter codes based on input string
  private filterCodes( value: string, type: string ): Code[] {

    if( typeof value !== 'string' )
    {
      return [];
    } 
    else 
    {
      const description = value.toLocaleLowerCase();
      switch( type )
      {
        case 'icd':
          return this.allIcdCodes.filter( code => {
            return code.description.toLocaleLowerCase().indexOf( description ) >= 0 || code.code.toLocaleLowerCase().indexOf( description ) >= 0;
          });
        break;
  
        default:
        case 'cpt':
          return this.allCptCodes.filter( code => {
            return code.description.toLocaleLowerCase().indexOf( description ) >= 0 || code.code.toLocaleLowerCase().indexOf( description ) >= 0;
          });
        break;
      }
    }
    
  } 
  
  // generic snack bar function
  openSnackBar( toolTip: string ) {
    this.snackBar.open(toolTip, 'Ok', {
      duration: 3000
    });
  }

}

