import { Component, ElementRef, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { AppComponent } from '@app/app.component';
import { Field } from '@app/models/field';
import { HttpService } from '@app/services/http.service';
import { LoaderService } from '@app/services/loader.service';
import { PageTitleService } from '@app/services/page-title.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { BaseComponent } from '@app/base.component';


@Component({
  selector: 'app-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.css']
})
export class FieldSelectComponent extends BaseComponent implements OnInit {

  // local variables
  fields: Field[] = [];
  urlBack: string = null;
  
  constructor(
    private pageTitleService: PageTitleService,
    private appComponent: AppComponent,
    private router: Router,
    private httpService: HttpService,
    private loaderService: LoaderService,
  ) {
    super();
  }
  
  ngOnInit(): void {
    this.appComponent.appendSiteTitle('Administration', 'Field', 'Select');
    this.pageTitleService.setPageTitle('Field Administration');

    this.urlBack = this.router.createUrlTree([ '/' ]).toString();
    this.fetchFields();
  }

  handleBack(): void {
    this.router.navigateByUrl( this.urlBack );
  }

  handleRowEdit( field: any): void {
    this.router.navigate(['administration', 'fields', 'edit', field.application_field_id]);
  }

  fetchFields(): void {
    this.loaderService.show();
    let urlApi: string = this.httpService.createUrl([
      'api',
      'fields'
    ], {}, { host: environment.host_api_default });

    this.httpService.get<any[]>(urlApi).pipe(
      tap( _ => null )
    ).subscribe(data => {
      this.fields = data;
    }, () => {
      // do nothing
    }, () => {
      this.loaderService.hide();
    });
  }
  
}
