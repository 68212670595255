<input #inputChart hidden="true" id="chart" type="file" accept="image/*" (change)="handleAddChart( $event )" />
<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>Chart</span>
        <span class="spacer"></span>
        <!-- https://stackoverflow.com/questions/8581081/how-to-access-a-mobiles-camera-from-a-web-app -->
        <ng-container *ngIf="hasButtonUpload">
            <button mat-raised-button type="button" aria-label="Skip" (click)="handleSkipStep()" *ngIf="hasNewPatient">
                Skip this step
            </button>
            <button mat-raised-button type="button" aria-label="Edit" [disabled]="!isChartLoaded" (click)="inputChart.click()">
                <!-- <mat-icon>file_upload</mat-icon> -->
                <mat-icon>add_a_photo</mat-icon>
            </button>
        </ng-container>
    </mat-toolbar-row>
</mat-toolbar>

<mat-card *ngIf="!chartImage && isChartLoaded" class="chart-placeholder-card" (click)="inputChart.click()">
    <mat-icon class="chart-placeholder-icon">
        camera_alt
    </mat-icon>
</mat-card>

<app-image-zoom [imageSource]="chartImage"></app-image-zoom>

<!--
<pinch-zoom *ngIf="chartImage">
    <img mat-card-image [src]="chartImage" alt="patient chart" />
</pinch-zoom>
-->