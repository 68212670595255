import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  title: string = '';

  constructor() {}

  public setPageTitle( title: string ): void
  {
    this.title = title;
  }

  public getPageTitle(): string
  {
    return this.title;
  }
}
