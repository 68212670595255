import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthCanActivateGuard } from './guards/auth-can-activate.guard';
import { RoleCanActivateGuard } from './guards/role-can-activate.guard';
import { DefaultComponent } from './layouts/default/default.component';

// use loadChildren to lazy load sub-modules
const routes: Routes = [
  { 
    data: {
      title: 'Family First Pediatrics'
    },
    path: '', 
    component: DefaultComponent,
    //canActivate: [AuthCanActivateGuard],
    canActivateChild: [AuthCanActivateGuard],
    children: [
      {
        data: {
          title: 'Home'
        },
        path: '', 
        loadChildren: () => import('./modules/default/default.module').then(m => m.DashboardModule) 
      },
      {
        data: {
          title: 'Account'
        },
        path: 'account', 
        loadChildren: () => import('./modules/authorization/authorization.module').then(m => m.AuthorizationModule) 
      },
      { 
        data: {
          title: 'Patient',
          roles: [ 'admin', 'provider' ]
        },
        path: 'patient', 
        loadChildren: () => import('./modules/patient/patient.module').then(m => m.PatientModule) 
      },
      { 
        data: {
          title: 'Billing',
          roles: [ 'admin', 'billing' ]
        },
        path: 'billing', 
        canActivateChild: [ RoleCanActivateGuard ],
        loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule) 
      },
      {
        data: {
          title: 'Admin',
          roles: [ 'admin' ]
        },
        path: 'administration',
        canActivateChild: [ RoleCanActivateGuard ],
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
