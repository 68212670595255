import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { BaseComponent } from '@app/base.component';
import { Patient } from '@app/models/patient';
import { HttpService } from '@app/services/http.service';
import { LoaderService } from '@app/services/loader.service';
import { PageTitleService } from '@app/services/page-title.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { first, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-add-chart',
  templateUrl: './patient-add-chart.component.html',
  styleUrls: ['./patient-add-chart.component.css']
})
export class PatientAddChartComponent extends BaseComponent implements OnInit {
  
  patient: Patient = null;
  patientId: Number = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appComponent: AppComponent,
    private httpService: HttpService,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.appComponent.appendSiteTitle( 'New-Patient-Chart' );
    this.pageTitleService.setPageTitle( 'New Patient Chart' );
    this.loaderService.show();
    
    const promiseAll = this.activatedRoute.params.pipe(
      first( params => params.patient_id ),
      take(1),
      map( params => {
        this.patientId = params.patient_id;
        return params;
      }),
      switchMap( () => this.fetchPatient() )
    );

    // run all promises using a pipe off of the activated route
    promiseAll
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      // do nothing
    }, error => console.error( error ), 
      () => {
        this.loaderService.hide();
    });
  }

  navigatePatientView(): void {
      // go to the patient view screen
      const urlPatientView = this.router.createUrlTree([
        'patient',
        'view',
        this.patient.patient_id
      ]);
      this.router.navigateByUrl( urlPatientView );    
  }

  handleImageUpload(): void {
    this.navigatePatientView();    
  }

  handleSkipStep(): void {
    this.navigatePatientView();
  }

  // get patient data from api
  fetchPatient(): Observable<Patient> {

    // patient api endpoint
    let urlApiPatient: string = this.httpService.createUrl([ 
      'api', 
      'patients',
      this.patientId
    ],
      { 'queryParams': { include: ['hospital', 'current_status'] }},
      { host: environment.host_api_default }
    );

    let patientObservable = this.httpService.get<Patient>( urlApiPatient )
      .pipe(
        map(( result: Patient ) => result as Patient ),
        map(( result ) => {
          this.patient = result;
          this.pageTitleService.setPageTitle(`Add Chart: ${this.patient.baby_last}`);
          // this.urlBack = this.router.createUrlTree( ['patient','select', this.patient.hospital_id ] ).toString();
          return result;
        })
      );
    return patientObservable;
  }

}
