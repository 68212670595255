import { Component, OnChanges, OnInit } from '@angular/core';
import { PageTitleService } from '@app/services/page-title.service';
import { AppComponent } from '@app/app.component';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '@app/services/http.service';
import { LoaderService } from '@app/services/loader.service';
import { Visit } from '@app/models/visit';
import { Code } from '@app/models/code';
import { Status } from '@app/models/status';
import { Observable, concat, merge } from 'rxjs';
import { map, tap, take, first, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { RouteDataService } from '@app/services/route-data.service';
import { HasChartValidator } from '@app/library/validators.class';
import { AuthService } from '@app/services/auth.service';
import { User } from '@app/models/user';
import { BaseComponent } from '@app/base.component';
import { Physician } from '@app/models/physician';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-patient-visit-add',
  templateUrl: './patient-visit-add.component.html',
  styleUrls: ['./patient-visit-add.component.css']
})
export class PatientVisitAddComponent extends BaseComponent implements OnInit, OnChanges {

  user: User;
  codes: Code[] = [];
  visits: Visit[] = [];
  physicians: Physician[] = [];
  physician: Physician = null;
  previousVisitCptCodes: Code[] = [];
  previousVisitIcdCodes: Code[] = [];
  urlBack: string = null;
  allIcdCodes: Code[] = [];
  allCptCodes: Code[] = [];
  statuses: Status[] = [];
  patientId: number = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appComponent: AppComponent,
    private httpService: HttpService,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
    private routeDataService: RouteDataService,
    private AuthService: AuthService,
    private snackBarService: MatSnackBar
  ) { 
    super();
  }
  
  ngOnInit(): void {
    this.loaderService.show();
    this.appComponent.appendSiteTitle( 'Patient', 'Visit', 'Add' );
    this.pageTitleService.setPageTitle( 'Patient Visit' );
    this.user = this.AuthService.getCurrentUser();
    
    this.physician = {
      physician_id: this.user.user_id,
      person_id: this.user.user_id
    };

    let promiseStatuses = this.routeDataService.routeData$.pipe(
      first(( data ) =>  data['statuses'] ),
      take(1),
      map( data => {
        this.statuses = data['statuses'];
      })
    );
  
    let promiseCodes = this.routeDataService.routeData$.pipe(
      first(( data ) =>  data['codes'] ),
      take(1),
      map( data => {
        let codes = data['codes'];
        this.allCptCodes = codes.filter(( code: Code ) => ( code.code_type === 'CPT' ? true : false ));
        this.allIcdCodes = codes.filter(( code: Code ) => ( code.code_type === 'ICD-10' ? true : false ));
        return codes;
      })
    );

    let promisePhysician = this.routeDataService.routeData$.pipe(
      first(( data ) =>  data['physicians'] ),
        take(1),
        map( data => {
          this.physicians = data['physicians'];
      })
    );  

    let promiseRoute = this.activatedRoute.params.pipe(
      first(( params ) => params.patient_id ),
      take(1),
      map( params => {
        this.patientId = params.patient_id;
        this.urlBack = this.router.createUrlTree([ 'patient', 'view', this.patientId ]).toString();
        return params;
      }),
      switchMap( () => this.fetchVisits() )
    );

    // run inital asyn promises synchronously 
    let promises = merge( promiseRoute, promiseCodes, promiseStatuses, promisePhysician );
    promises
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
    }, 
      error => console.error( error ),
      () => { 
        this.loaderService.hide(); 
      }
    );
  }

  // get current visits
  fetchVisits(): Observable<any> {

    // patient api endpoint
    let urlApiVisit: string = this.httpService.createUrl([ 
      'api', 
      'visits',
      'patient',
      this.patientId
    ],
      { 'queryParams': { include: [ 'codes' ] } },
      { host: environment.host_api_default }
    );

    let promiseVisits = this.httpService.get<Visit[]>( urlApiVisit )
      .pipe(
        tap( _ => null ),
        map(( result: Visit[] ) => result as Visit[] ),
        map(( result ) => { 
          this.visits = Visit.mapToVisits( result );
          this.previousVisitCptCodes = Visit.extractVisitCodesByType( this.visits, 'CPT' );
          this.previousVisitIcdCodes = Visit.extractVisitCodesByType( this.visits, 'ICD-10' );
        }),
      );

    return promiseVisits;
  }

  // handle data changes
  ngOnChanges(): void {
  }

  // what happens when the back button is clicked
  handleBack(): void {
    this.router.navigateByUrl( this.urlBack ); // navigate() takes in the same paramaters as url tree (see above)
  }

  hasChart(): Observable<boolean> {
    return HasChartValidator(this.httpService, this.patientId)
    .pipe(
      takeUntil(this.appUnsubscribe),
      map(result => {
        if (!result) {
          this.snackBarService.open(`Unable to complete patient: Chart is required`, `Dismiss`, {
            duration: 5000
          });
          throw new Error("No chart exists!");
        }
        return result;
      }));
  }

  // handle patient complete
  handlePatientComplete(): Observable<Status> {

    let status = Status.filterBySlug( this.statuses, 'COMPLETED' );

    let put = {
      'status_id': status.status_id
    };

    let urlApiPatient: string = this.httpService.createUrl([
      'api',
      'patients',
      this.patientId
    ],
      { },
      { host: environment.host_api_default }
    );
    return this.httpService.put<Status>( urlApiPatient, put );
  }

  // handle submit button
  handleSubmit( event ): void {

    let observer: Observable<any>;
    let visit = event.visit;

    // add required properties
    visit.patient_id = this.patientId;
    visit.owner_id = this.user.user_id;

    // add new visit to the database
    const urlApi = this.httpService.createUrl([
      'api',
      'visits'
    ], {}, {
      host: environment.host_api_default
    });

    if( event.complete == true ){
      observer = concat(
        this.hasChart(),
        this.handlePatientComplete(),
        this.httpService.post( urlApi, visit )
      );
    } else {
      observer = this.httpService.post( urlApi, visit );
    }
    
    // send visit to the database
    this.loaderService.show();
    observer.subscribe( result => {
      // return user to previous page
    }, ( error ) => {
      this.loaderService.hide();
    }, () => {
      this.loaderService.hide();
      this.router.navigateByUrl( this.urlBack );
    });
  }
}
