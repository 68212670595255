export class Status {
    status_id: number;
    status_name: string;
    status_order: number;
    status_slug: string;

    public static filterBySlug( statuses: Status[], slug: string ): Status {
        let status: Status = null;
        statuses.forEach( s => {
            if( s.status_slug == slug ){
                status = s;
            }
        });
        return status;
    }
}
