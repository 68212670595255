<mat-toolbar color="accent">
    <mat-toolbar-row>
      <span>
        {{ subTitle }}
      </span>
      <span class="spacer"></span>
      <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </mat-toolbar-row>
</mat-toolbar>

<div class="flex-container">
    <form [formGroup]="fieldForm" *ngIf="isFormLoaded()" (ngSubmit)="handleSubmit()" class="flex-item flex-container">

        <div class="field-container">
            
            <div>
                <label>Table Name</label>
                <span>{{ field.table_name }}</span>
            </div>

            <div>
                <label>Field Name</label>
                <span>{{ field.field_name }}</span>
            </div>
            
        </div>
            
        <div class="field-container">
            <mat-slide-toggle formControlName="display_field" class="slider-toggle" >Display this field on view and edit screens?</mat-slide-toggle>
            <mat-slide-toggle formControlName="require_field" class="slider-toggle" >Require this field to be filled?</mat-slide-toggle>
        </div>

        <div class="controls">
            <button name="button-submit" [disabled]="getIsSubmitActive()"  mat-raised-button type="submit" color="primary">Submit</button>
        </div>
    </form>
</div>