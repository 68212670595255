import { Component, OnInit } from '@angular/core';
import { AppComponent } from '@app/app.component';
import { HttpService } from '@app/services/http.service';
import { PageTitleService } from '@app/services/page-title.service';
import { concat } from 'rxjs';
import { map, first, take, takeUntil } from 'rxjs/operators';
import { Hospital } from '@app/models/hospital';
import { Patient } from '@app/models/patient';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { LoaderService } from '@app/services/loader.service';
import { environment } from '@env/environment';
import { AuthService } from '@app/services/auth.service';
import { User } from '@app/models/user';
import { Status } from '@app/models/status';
import { RouteDataService } from '@app/services/route-data.service';
import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-patient-add',
  templateUrl: './patient-add.component.html',
  styleUrls: ['./patient-add.component.css']
})
export class PatientAddComponent extends BaseComponent implements OnInit {

  initialValues: any = {};
  hospitals: Hospital[] = [];
  urlBack: UrlTree = null;
  statuses: Status[] = null;
  private user: User = null;

  constructor( 
    private httpService: HttpService,
    private appComponent: AppComponent,
    private pageTitleService: PageTitleService,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService,
    private router: Router,
    private AuthService: AuthService,
    private routeDataService: RouteDataService
  ){ 
    super();
  }

   // when component initializes
   ngOnInit(): void {
    this.urlBack = this.router.createUrlTree( ['/'] );
    this.loaderService.show();
    this.appComponent.appendSiteTitle( 'Patient', 'Add' );
    this.pageTitleService.setPageTitle( 'Add Patient' );
    this.user = this.AuthService.getCurrentUser();

    let promiseRouteData = this.routeDataService.routeData$.pipe(
      first(( data ) =>  data.hospitals && data.statuses ),
      take(1),
      map( data => {
        this.statuses = data['statuses'].filter( status => status.status_slug === 'ACTIVE' ) as Status[];
        this.hospitals = data['hospitals'] as Hospital[];

        // set initial status to active
        this.statuses.forEach( status => {
          if( status.status_slug === 'ACTIVE' ){
            this.initialValues.status_id = status.status_id;
          }
        });

        return data;
      })
    );

    let promises = concat( promiseRouteData );
    promises
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      // check for hospital id
      this.activatedRoute.params
      .pipe( takeUntil( this.appUnsubscribe ) )
      .subscribe( result => {
        if( result.hospital_id ) {
          this.initialValues.hospital_id = parseInt( result.hospital_id );
        }
      });
    }, 
      error => console.error( error), 
      () => { this.loaderService.hide(); }
    );
  } 

  // when back button is clicked
  handleBack(): void {
    this.router.navigateByUrl( this.urlBack );
  }

  // handle form submit
  onSubmit( patient: Patient ): void {

    //  show loader
    this.loaderService.show();

    // add required properties
    patient.owner_id = this.user.user_id;

    // this.loaderService.show();
    let urlApiPatient: string = this.httpService.createUrl([ 
      'api', 
      'patients'
    ],
      {},
      { host: environment.host_api_default }
    );

    let update = this.httpService.post<Patient>( urlApiPatient, patient );
    update.subscribe( patient => {
      
      // go to the add chart screen
      const urlPatientView = this.router.createUrlTree([
        'patient',
        'view',
        patient.patient_id,
        'chart'
      ]);
      this.loaderService.hide();
      this.router.navigateByUrl( urlPatientView );

    });
  }

}
