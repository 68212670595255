import { Component, OnInit } from '@angular/core';
import { UrlTree, Router, ActivatedRoute } from '@angular/router';
import { takeUntil, tap, take, map, first } from 'rxjs/operators';
import { AppComponent } from '@app/app.component';
import { PageTitleService } from '@app/services/page-title.service';
import { Patient } from '@app/models/patient';
import { Status } from '@app/models/status';
import { HttpService } from '@app/services/http.service';
import { LoaderService } from '@app/services/loader.service';
import { environment } from '@env/environment';
import { BaseComponent } from '@app/base.component';
import { RouteDataService } from '@app/services/route-data.service';

@Component({
  selector: 'app-patient-list-all',
  templateUrl: './patient-list-all.component.html',
  styleUrls: ['./patient-list-all.component.css']
})
export class PatientListAllComponent extends BaseComponent implements OnInit {

  columnsToDisplay: string[] = [ 'id', 'name', 'username' ];   
  patients: Patient[] = [];
  statuses: Status[] = [];
  expandedElement: any | null;
  hasData: boolean = false;
  urlBack: string = null;

  constructor(
    private pageTitleService: PageTitleService,
    private appComponent: AppComponent,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private routeDataService: RouteDataService
  ) { 
    super();
  }
  
  fetchPatients(): void {

    this.loaderService.show();

    let urlApi: string = this.httpService.createUrl([ 
      'api', 
      'patients'
    ], 
      { "queryParams": { status: [ "completed", "active" ], include: [ "hospital", "current_status" ] }},
      { host: environment.host_api_default }
    );

    this.httpService.get( urlApi )
      .pipe(
        tap( _ => null )
      ).subscribe(data => {
        this.patients = data;
      }, (error) => {
        // do nothing
      }, () => {
        this.loaderService.hide();
    });

  }

  // what to do when the edit button is clicked
  onRowView( element: Patient ): void {
    // UrlTree is a representation of a URL in object form, see https://angular.io/api/router/UrlTree
    // the Router has methods to handle all URL construction/manipulation, see https://angular.io/api/router/Router
    // the router.navigateByUrl() method takes in a URL string or UrlTree, it seems that UrlTree is the best method to build a URL
    // to generate a UrlTree, use the this.router.createUrlTree() method
    // UrlTree(['patient','edit', element.id, { a: 'b' } ]); // how to add parameters to route
    // UrlTree(['patient','edit', element.id ], { queryParams: {}}); // how to add query parameters
    let urlPatientView: UrlTree = this.router.createUrlTree( [ 'patient', 'view', element.patient_id ] );
    this.router.navigateByUrl( urlPatientView ); // navigate() takes in the same paramaters as url tree (see above)
  }

  ngOnInit(): void {
    this.appComponent.appendSiteTitle( 'Patient', 'List', 'All' );
    this.pageTitleService.setPageTitle( 'All Patients' );

    this.routeDataService.routeData$.pipe(
      takeUntil( this.appUnsubscribe ),
      first(( data ) => data.statuses ),
      take(1),
      map( data => {
        this.statuses = data['statuses'].filter( status => status.status_slug === 'ACTIVE' ||  status.status_slug === 'COMPLETED' ) as Status[];
        return data;
      })
    ).subscribe( result => {
      // do nothing
    });

    // go back to dashboard
    this.fetchPatients();
  }

}
