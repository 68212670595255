<div class="flex-container">
    <form [formGroup]="patientForm" (ngSubmit)="handleSubmit()" class="flex-item flex-container" *ngIf="formReady">

        <mat-form-field appearance="fill">
            <mat-label>Hospital</mat-label>
            <mat-select name="hospital_id" formControlName="hospital_id" required>
                <mat-option *ngFor="let hospital of hospitals" [value]="hospital.hospital_id">{{hospital.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Status</mat-label>
            <mat-select name="status_id" formControlName="status_id" required>
                <mat-option *ngFor="let status of statuses" [disabled]="statusIsDisabled(status.status_name)" [value]="status.status_id">{{status.status_name + (statusIsDisabled(status.status_name) ? " (Chart Required)" : "")}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="displayFields.baby_first">
            <mat-label>Baby's First</mat-label>
            <input matInput name="baby_first" formControlName="baby_first" maxlength="50" [required]="requireFields['baby_first']" />
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="displayFields.baby_last">
            <mat-label>Baby's Last</mat-label>
            <input #babyLast matInput name="baby_last" formControlName="baby_last" maxlength="50" [required]="requireFields['baby_last']" />
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="displayFields.mother_first">
            <mat-label>Mother's First</mat-label>
            <input matInput name="mother_first" formControlName="mother_first" maxlength="50" [required]="requireFields['mother_first']" />
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="displayFields.mother_last">
            <mat-label>Mother's Last</mat-label>
            <input matInput name="mother_last" formControlName="mother_last" maxlength="50" [required]="requireFields['mother_last']" />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Sex of Child</mat-label>
            <mat-select name="sex" formControlName="sex" required>
                <mat-option *ngFor="let sex of sexes" value="{{sex.id}}">{{sex.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Baby's Date of Birth</mat-label>
            <input name="birth_date" matInput [matDatepicker]="picker_birth_date" formControlName="dob" required />
            <mat-datepicker-toggle matSuffix [for]="picker_birth_date"></mat-datepicker-toggle>
            <mat-datepicker #picker_birth_date></mat-datepicker> 
        </mat-form-field>

        <div class="field-container" *ngIf="displayFields.has_ffp_visit || displayFields.has_future_ffp_visit">
            <mat-slide-toggle *ngIf="displayFields.has_ffp_visit" formControlName="has_ffp_visit" class="slider-toggle" [checked]="false">Household Seen At FFP?</mat-slide-toggle>
            <mat-slide-toggle *ngIf="displayFields.has_future_ffp_visit" formControlName="has_future_ffp_visit" class="slider-toggle" [checked]="false">Patient To Be Seen At FFP?</mat-slide-toggle>
        </div>

        <mat-form-field appearance="fill" *ngIf="displayFields.insurance">
            <mat-label>Insurance</mat-label>
            <input matInput name="insurance" formControlName="insurance" maxlength="250" [required]="requireFields['insurance']" />
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="displayFields.guarantor">
            <mat-label>Guarantor</mat-label>
            <input matInput name="guarantor" formControlName="guarantor" maxlength="250" [required]="requireFields['guarantor']" />
        </mat-form-field>

        <div class="controls">
            <button name="button-submit" [disabled]="getIsSubmitActive()" mat-raised-button type="submit" color="primary">Submit</button>
            <button *ngIf="canDelete" name="button-delete" mat-raised-button type="submit" color="error">Delete</button>
        </div>

    </form>
</div>