import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpService } from '@app/services/http.service';
import { PasswordStrengthValidator, ConfirmPasswordValidator, SelectedRoleValidator } from '../../../library/validators.class';
import { environment } from '@env/environment';
import { Role } from '../../../models/role';
import { ActivatedRoute, Router } from '@angular/router';
import { Person } from '@app/models/person';
import { takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppComponent } from '@app/app.component';
import { PageTitleService } from '@app/services/page-title.service';
import { LoaderService } from '@app/services/loader.service';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeDialogComponent } from '../password-change-dialog/password-change-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent extends BaseComponent implements OnInit {

  // variables
  private isLoaded: boolean = false;
  isRegister: boolean = true;
  subTitle: string = "Register a new User";
  hidePassword: boolean = true;
  activeRoles: Role[];
  urlBack: string = null;
  personId: number = null;

  // patient form
  personForm: UntypedFormGroup = new UntypedFormGroup({});

  constructor(
    private httpService: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
    private passwordDialog: MatDialog,
    private snackBarService: MatSnackBar
  ) {
    super();
    this.urlBack = this.router.createUrlTree(['/administration/users']).toString();
    this.activatedRoute.params.subscribe(params => {
      if (params.person_id) {
        this.personId = params.person_id;
        this.isRegister = false;
        this.subTitle = "User Information"
      }

      const urlApi = this.httpService.createUrl([
        'api',
        'roles'
      ], {}, { host: environment.host_api_default });

      this.httpService.get<Role[]>(urlApi).subscribe((data: Role[]) => {
        this.activeRoles = data;
        this.personForm.addControl('first_name', new UntypedFormControl(null, { validators: Validators.required }));
        this.personForm.addControl('last_name', new UntypedFormControl(null, { validators: Validators.required }));
        this.personForm.addControl('preferred_name', new UntypedFormControl(null, {}));
        this.personForm.addControl('phone', new UntypedFormControl(null, { validators: [Validators.required, Validators.pattern('^(\\+?\d{1,4}[\s-])?(?!0+\s+,?$)\\d{10}\s*,?$')] }));
        this.personForm.addControl('username', new UntypedFormControl(null, { validators: Validators.required }));
        this.personForm.addControl('email', new UntypedFormControl(null, { validators: [Validators.required, Validators.email] }));
        if (this.isRegister) {
          this.personForm.addControl('password', new UntypedFormControl(null, { validators: [Validators.required, PasswordStrengthValidator] }));
          this.personForm.addControl('confirm_password', new UntypedFormControl(null, { validators: [Validators.required, (control => ConfirmPasswordValidator(control, this.personForm))] }));
        }
        this.personForm.addControl('has_password_change', new UntypedFormControl(true, { }));
        this.personForm.addControl('title', new UntypedFormControl(null, {}));
        this.personForm.addControl('roles', new UntypedFormControl(null, { validators: [SelectedRoleValidator] }));

        if (!this.isRegister) {
          this.fetchPerson(this.personId).pipe(
            tap( _ => null )
          ).subscribe(data => {
            let roleValues = [];
            this.personForm.patchValue({ first_name: data.first_name });
            this.personForm.patchValue({ last_name: data.last_name });
            this.personForm.patchValue({ preferred_name: data.preferred_name });
            this.personForm.patchValue({ title: data.physician.title });
            this.personForm.patchValue({ phone: data.phone });
            this.personForm.patchValue({ username: data.user.username });
            this.personForm.patchValue({ email: data.user.email });
            this.personForm.patchValue({ has_password_change: data.user.has_password_change });
            this.activeRoles.forEach(role => {
              let result = data.user.roles.findIndex((r) => r.role_id == role.role_id );
              if (result > -1) {
                roleValues.push(role.role_id);
              }
            });
            this.personForm.patchValue( {roles: roleValues });
          }, () => {
            // do nothing
          }, () => {
            this.isLoaded = true;
          });
        } else {
          this.isLoaded = true;
        }
      });
    });
  }

  handleBack(): void {
    this.router.navigateByUrl(this.urlBack);
  }

  isFormLoaded(): boolean {
    return this.isLoaded;
  }
  // handle whether or not submit button should be active
  getIsSubmitActive(): boolean {
    return !this.personForm.valid;
  }

  changePassword(): void {
    const passDialog = this.passwordDialog.open(PasswordChangeDialogComponent, {
      width: '300px',
      data: { user_id: this.personId, required: false, is_current_user: false }
    });

    passDialog.afterClosed()
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe(result => {
      if (result != null) {
        this.snackBarService.open(`Password change ${result}`, `Ok`, {
          duration: 3000
        });
      }
    });
  }

  // handle form submit
  handleSubmit(): void {
    this.personForm.controls.roles.markAsDirty();
    this.personForm.controls.roles.updateValueAndValidity();
    if (this.personForm.valid) {
      this.loaderService.show();
      if (this.isRegister) {
        
        this.createPerson();
      } else {
        this.updatePerson();
      }
    }
  }

  createPerson(): void {
    const urlApi = this.httpService.createUrl([
      'admin',
      'register'
    ], {}, { host: environment.host_api_default });
    
    let create = this.httpService.post<any>(urlApi, this.personForm.value);
    create.subscribe( person => {
      this.loaderService.hide();

      const urlPersonView = this.router.createUrlTree([
        'administration',
        'users',
        person.person_id
      ]);

      this.router.navigateByUrl( urlPersonView );
    });
  }

  updatePerson(): void {
    const urlApi = this.httpService.createUrl([
      'admin',
      'users',
      this.personId,
      'update'
    ], {}, { host: environment.host_api_default});

    let update = this.httpService.put(urlApi, this.personForm.value);
    update.subscribe(person => {
      this.loaderService.hide();

      const urlPersonView = this.router.createUrlTree([
        'administration',
        'users',
        person.person_id  
      ]);

      this.router.navigateByUrl( urlPersonView );
    });
  }

  fetchPerson(person_id: number): Observable<Person> {
    let urlApi: string = this.httpService.createUrl([
      'api',
      'users',
      person_id
    ], {}, { host: environment.host_api_default });

    return this.httpService.get<Person>(urlApi);
  }

  ngOnInit(): void {
    this.pageTitleService.setPageTitle('Users Administration');
    this.appComponent.appendSiteTitle('administration', 'users', 'edit')
  }
}
