import { Injectable, ComponentRef, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingOverlayComponent } from '@app/shared/components/loading-overlay/loading-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private overlayRef: OverlayRef = null;

  constructor(
    private injector: Injector,
    private overlay: Overlay
  ) 
  { }

  // show the dialog
  show(): void {
    if( !this.overlayRef )
    {
      this.overlayRef = this.createOverlay();
    }
    const overlayPortal = new ComponentPortal( LoadingOverlayComponent );
    const component = this.overlayRef.attach( overlayPortal );
  }

  // hide the dialog
  hide(): void {
    if( !!this.overlayRef ){
      this.overlayRef.detach();
    }
  }

  // configure the overlay
  private createOverlay(): OverlayRef {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return this.overlay.create( overlayConfig );
  }

}
