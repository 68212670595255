<form [formGroup]="passwordForm" (ngSubmit)="handleSubmit()" class="flex-item flex-container">
    <mat-label *ngIf="required">
        <h3>A Password Change Is Required</h3>
        <div>Please enter a new password</div>
    </mat-label>
    <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput name="password" [type]="hidePassword ? 'password' : 'text'" formControlName="password" maxlength="100" minlength="8" />
        <button mat-icon-button matSuffix type="button" tabindex="-1" (click)="hidePassword = !hidePassword">
            <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
        <mat-error *ngIf="passwordForm.controls['password'].hasError('passwordStrength') && !passwordForm.controls['password'].hasError('minlength')">
            <div *ngFor="let error of passwordForm.controls['password'].errors | keyvalue">
                {{ error.value }}
            </div>
        </mat-error>
        <mat-error *ngIf="passwordForm.controls['password'].hasError('minlength') && !passwordForm.controls['password'].hasError('required')">
            Minimum of 8 character required.
        </mat-error>
        <mat-error *ngIf="passwordForm.controls['password'].hasError('required')">
            Password is required.
        </mat-error>
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>Confirm Password</mat-label>
        <input matInput name="confirm_password" [type]="hidePassword ? 'password' : 'text'" formControlName="confirm_password" maxlength="100" />
        <mat-error *ngIf="passwordForm.controls['confirm_password'].hasError('confirmPassword')">
            Confirm password does not match password.
        </mat-error>
    </mat-form-field>

    <div class="controls">
        <button mat-raised-button *ngIf="!required" type="button" color="primary" (click)="cancelDialog()">Cancel</button>
        <button mat-raised-button [disabled]="getIsSubmitActive()" type="submit" color="primary">Submit</button>
    </div>
</form>