import { Patient } from '@app/models/patient';
import { Code } from '@app/models/code';
import { Physician } from '@app/models/physician';

export class Visit {
    visit_id?: number;
    patient_id?: number;
    physician_id?: number;
    owner_id?: number;
    visit_date?: Date;
    created_at?: Date;
    cpt_codes?: CPTCode[];
    icd10_codes?: ICD10Code[];
    notes?: string;
    patient?: Patient;
    physician?: Physician;
    codes?: Code[];

    public extractCodesByType( type: string ): Code[] {
        let extraction: Code[] = [];
        this.codes.forEach( code => {
            if( code.code_type === type )
            {
                extraction.push( code );
            }
        });
        return extraction;
    }

    /**
     * Takes in an array of objects and maps to visit classes.
     * @param data represents javascript object data
     */
    public static mapToVisits( data ): Visit[] {
        let visits: Visit[] = [];
        data.forEach( element => {
            let visit = new Visit();
            Object.assign( visit, element );
            visits.push( visit );
        })
        return visits;
    }

    /**
     * 
     * @param visits array of visit objects
     * @param type type of code to extract
     * @returns 
     */
    public static extractVisitCodesByType( visits: Visit[], type: string ): Code[] {
        let extraction: Code[] = [];
        visits.forEach( visit => {
            extraction = extraction.concat( visit.extractCodesByType( type ));
        });
        return extraction;
    }

    public static aggregateVisitNotes( visits: Visit[] ): string {
        let aggregation: string = '';
        visits.forEach( visit => {
            if( visit.notes ){
                aggregation = aggregation.concat( visit.notes.concat(` `) );
            }
        });
        return aggregation;
    }
}

export class CPTCode {
    id?: number;
    code: string;
    description: string;
}

export class ICD10Code {
    id?: number;
    code: string;
    description: string;
}