import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '@app/shared/components/sidebar/sidebar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientVisitListComponent } from '@app/shared/components/patient-visit-list/patient-visit-list.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatChipsModule } from '@angular/material/chips';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HospitalListComponent } from '@app/shared/components/hospital-list/hospital-list.component';
import { HospitalPatientListComponent } from '@app/shared/components/hospital-patient-list/hospital-patient-list.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { LoadingOverlayComponent } from '@app/shared/components/loading-overlay/loading-overlay.component';
import { PatientListComponent } from '@app/shared/components/patient-list/patient-list.component';
import { PatientDetailComponent } from '@app/shared/components/patient-detail/patient-detail.component';
import { PatientChartComponent } from '@app/shared/components/patient-chart/patient-chart.component';
import { PatientVisitDetailComponent } from '@app/shared/components/patient-visit-detail/patient-visit-detail.component';
import { PatientFormComponent } from '@app/shared/components/patient-form/patient-form.component';
import { MatDatepickerModule }  from '@angular/material/datepicker';
import { PatientVisitFormComponent } from '@app/shared/components/patient-visit-form/patient-visit-form.component';
// import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { FieldListComponent } from '@app/shared/components/field-list/field-list.component';
import { ImageZoomComponent } from './components/image-zoom/image-zoom.component';

@NgModule({
  declarations: [
    SidebarComponent,
    PatientVisitListComponent,
    HospitalListComponent,
    HospitalPatientListComponent,
    LoadingOverlayComponent,
    PatientListComponent,
    PatientDetailComponent,
    PatientChartComponent,
    PatientVisitDetailComponent,
    PatientFormComponent,
    PatientVisitFormComponent,
    FieldListComponent,
    ImageZoomComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatCardModule,
    MatDividerModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatInputModule,
    MatChipsModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSelectModule,
    CommonModule,
    RouterModule,
    LayoutModule,
    MatTableModule,
    MatSortModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    // PinchZoomModule
  ],
  exports: [
    SidebarComponent,
    PatientVisitListComponent,
    HospitalListComponent,
    HospitalPatientListComponent,
    LoadingOverlayComponent,
    PatientListComponent,
    PatientDetailComponent,
    PatientChartComponent,
    PatientVisitDetailComponent,
    PatientVisitFormComponent,
    PatientFormComponent,
    FieldListComponent
  ]
})
export class SharedModule { }
