// https://stackoverflow.com/questions/48759538/do-you-need-to-unsubscribe-from-output-eventemitter // we don't need to unsubscribe from input/output variables

import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { RouteDataService } from '@app/services/route-data.service';
import { HttpService } from './services/http.service';
import { environment } from '@env/environment';
import { tap, shareReplay } from 'rxjs/operators';
import { Code } from '@app/models/code'; 
import { AuthService } from './services/auth.service';
import { Physician } from './models/physician';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  showOverlay: boolean = false; //
  siteTitle: string = 'Families First Pediatrics';
  previousUrl: string = null;
  currentUrl: string = null;

  public constructor( 
    private titleService: Title,
    private router: Router,
    private routeDataService: RouteDataService,
    private httpService: HttpService,
    private authService: AuthService
  ){
    // set this initial title
    this.setTitle( this.siteTitle );

    // set up listening on router
    router.events.subscribe(( event: RouterEvent ) => {
      this.navigationInterceptor( event );
    });    
  }

  // handle lifecycle events from the router
  private navigationInterceptor( event: RouterEvent ):void {

    const urlLogin = this.router.createUrlTree([
      'account',
      'login'
    ]).toString();
    
    if( event instanceof NavigationStart ){
      // this.showOverlay = true;
    } else if ( event instanceof NavigationEnd ){
      // this.showOverlay = false;
    } else if ( event instanceof NavigationCancel ){
      // this.showOverlay = false;
    } else if ( event instanceof NavigationError ){
      // this.showOverlay = false;
    }
  }

  // respond after angular checks the content projected into the directive or component.
  ngAfterContentInit() {

    this.authService.isAuthenticated$.subscribe(result => {
      if ( result === true ) {
        // initialize static content ( codes )
        const urlApiCode = this.httpService.createUrl([
          'api',
          'codes'
        ],
          {},
          { host: environment.host_api_default }
        )
        this.httpService.get<Code[]>( urlApiCode ).pipe(
          tap( _ => null ),
          shareReplay(1) // only run this operation once per application
        ).subscribe( result => {
          this.routeDataService.setRouteData( result, 'codes' );
        });
    
        // initialize static content ( statuses )
        const urlApiStatus = this.httpService.createUrl([
          'api',
          'statuses'
        ],
          {},
          { host: environment.host_api_default }
        )
        this.httpService.get<Code[]>( urlApiStatus ).pipe(
          tap( _ => null ),
          shareReplay(1) // only run this operation once per application
        ).subscribe( result => {
          this.routeDataService.setRouteData( result, 'statuses' );
        });
    
        // initialize static content ( hospitals )
        const urlApiHospital = this.httpService.createUrl([
          'api',
          'hospitals'
        ],
          {},
          { host: environment.host_api_default }
        )
        this.httpService.get<Code[]>( urlApiHospital ).pipe(
          tap( _ => null ),
          shareReplay(1) // only run this operation once per application
        ).subscribe( result => {
            this.routeDataService.setRouteData( result, 'hospitals' );
        });

        // initialize static content ( hospitals )
        const urlUsers = this.httpService.createUrl([
          'api',
          'physicians'
        ],
          {},
          { host: environment.host_api_default }
        )
        this.httpService.get<Physician[]>( urlUsers ).pipe(
          tap( _ => null ),
          shareReplay(1) // only run this operation once per application
        ).subscribe( result => {
            this.routeDataService.setRouteData( result, 'physicians' );
        });
      } else {
        // not authorized
      }
    });
     
  }
  
  // Called once, after the first ngOnChanges()
  ngOnInit(){
    // handle routing events  
    this.router.events.pipe(
      filter(( event ) => event instanceof NavigationEnd ),
      tap( _ => null )
    ).subscribe(( event:NavigationEnd ) => {
      this.routeDataService.setPreviousUrl( this.currentUrl );
      this.currentUrl = event.url;
    });

    this.initializeUserMonitoring();
  }

  // monitor user authentication, if user loses authentication, route to the login screen
  private initializeUserMonitoring(): void {
    // route users to login page if not authenticated 
    this.authService.isAuthenticated$.pipe(
      // first( value => value === false ) // this doesn't work when you click the logout link
    ).subscribe( value => {
      if( value === false )
      {
        this.navigateToLogin();
      }
    });
  }

  // navigate to the login screen
  navigateToLogin(): void {
    const urlLogin = this.authService.getUrlLogin();
    this.router.navigateByUrl( urlLogin );
  }

  setTitle( newTitle: string ){
    this.titleService.setTitle( newTitle );
  }

  getTitle(): string {
    return this.titleService.getTitle();
  }

  resetSiteTitle()
  {
    this.setTitle( this.siteTitle );
  }

  appendSiteTitle( ...titles: string[] ): string
  {
    // set this back to base value
    this.resetSiteTitle(); 

    let existingTitles: string[] = this.titleService.getTitle().split(' | ');
    existingTitles = [ ...existingTitles, ...titles ];
    let title = existingTitles.join(' | ');
    this.setTitle( title );
    return this.getTitle();
  }

}
