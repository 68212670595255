import { Component, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnChanges, AfterViewInit, AfterViewChecked } from '@angular/core';
import { Field } from '@app/models/field';
import { MatSort } from '@angular/material/sort';
import { ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-field-list',
  templateUrl: './field-list.component.html',
  styleUrls: ['./field-list.component.css']
})
export class FieldListComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  // local variables
  fieldData: MatTableDataSource<Field> = new MatTableDataSource<Field>([]);

  // @Input() onRowEdit: Function;
  @Input('fields') fields: Field[] = [];
  @Input('hasData') hasData: boolean = false;
  @Input('hasButtonBack') hasButtonBack: boolean = false;
  @Input('hasButtonAdd') hasButtonAdd: boolean = false;
  @Output('onRowEdit') onRowEdit: EventEmitter<Field> = new EventEmitter<Field>();
  @Output('onRowView') onRowView: EventEmitter<Field> = new EventEmitter<Field>();
  @Output('onBack') onBack: EventEmitter<any> = new EventEmitter<any>();

  // viewchild objects
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('filter') filter: ElementRef;

  columnsToDisplay: string[] = ['edit', 'table_name', 'field_name', 'display_field', 'require_field' ];
  expandedElement: any | null;
  themeColor: ThemePalette = "primary";

  constructor() {
  }

  // initialization of component
  ngOnInit(): void {
  }

  // when input/output data changes
  ngOnChanges(): void {
    if( this.fields ) {
      this.fieldData = new MatTableDataSource<Field>( this.fields );
      this.fieldData.sort = this.sort;
    }
  }

  // after view has initialized
  ngAfterViewInit(): void {
    // when using after view init, for some reason you have to run a slight delay before calling focus
    setTimeout(() => {
      this.filter.nativeElement.focus();
    }, 100);
  }
  
  // after angular checks the component's views
  ngAfterViewChecked(): void {
  }

  // what to do when table filter is applied
  handleFilter( event: any ): void {
    let text = event.target.value;
    this.fieldData.filter = text.trim().toLocaleLowerCase();
  }

  // what happens when back button is clicked
  handleBack(): void {
    this.onBack.emit();
  }

  // action when edit button is clicked
  handleRowEdit( element: Field ): void {
    this.onRowEdit.emit( element );
  }

  // what to do when view button is clicked on
  handleRowView( element: Field ): void {
    this.onRowView.emit( element );
  }

}
