<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>Details</span>
        <span class="spacer"></span>
        <span>
            <button type="button" color="primary" aria-label="Change Password" mat-raised-button (click)="changePassword()">
                <mat-icon>lock</mat-icon> 
                <span>Password</span>
            </button>
        </span>
        <span>
            <button type="button" color="warn" aria-label="Logout" mat-raised-button (click)="handleButtonLogout()">
                <mat-icon>logout</mat-icon> 
                <span> Logout</span>
            </button>
        </span>
    </mat-toolbar-row>
</mat-toolbar>

<div class="field-container" *ngIf="user">
    <div>
        <label>User Name</label>
        <span>{{user.username}}</span>
    </div>
    <div>
        <label>Preferred Name</label>
        <span>{{user.preferred_name}}</span>
    </div>
    <div>
        <label>First Name</label>
        <span>{{user.first_name}}</span>
    </div>
    <div>
        <label>Last Name</label>
        <span>{{user.last_name}}</span>
    </div>
    <div>
        <label>Email</label>
        <span>{{user.email}}</span>
    </div>
    <div>
        <label>Phone</label>
        <span>{{user.phone}}</span>
    </div>
    <div>
        <label>User ID</label>
        <span>{{user.user_id}}</span>
    </div>
    <div>
        <label>Person ID</label>
        <span>{{user.user_id}}</span>
    </div>
</div>