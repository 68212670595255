<app-patient-detail 
    [visits]="visits"
    [patient]="patient" 
    [hasButtonBack]="true" 
    [hasButtonEdit]="true" 
    [hasButtonComplete]="true"
    [filterFields]="true" 
    (onButtonBack)="handleBack()" 
    (onButtonEdit)="handleEdit()"
    (onButtonComplete)="handleComplete()">
</app-patient-detail>

<app-patient-visit-list 
    [visits]="visits" 
    [hasButtonAdd]="true" 
    (onAddVisit)="handleAddVisit()" 
    (onViewVisit)="handleViewVisit( $event )">
</app-patient-visit-list>

<app-patient-chart 
    [patient]="patient" 
    [hasButtonUpload]="true">
</app-patient-chart>