import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Patient } from '@app/models/patient';
import { Visit } from '@app/models/visit';
import { Code } from '@app/models/code';
import { Field } from '@app/models/field';
import { Observable, throwError } from 'rxjs';
import { tap, map, takeUntil, mergeMap } from 'rxjs/operators';
import { HttpService } from '@app/services/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env/environment';
import { HasChartValidator } from '@app/library/validators.class';
import { BaseComponent } from '@app/base.component';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { LoaderService } from '@app/services/loader.service';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css']
})
export class PatientDetailComponent extends BaseComponent implements OnInit, OnChanges {

  fields: Field[] = [];
  allVisitNotes: string = '';
  cptColor: ThemePalette = "warn";
  icdColor: ThemePalette = "accent";
  previousVisitCptCodes: Code[] = [];
  previousVisitIcdCodes: Code[] = [];
  displayFields: any = {
    'patient_id': true,
    'baby_first': true,
    'baby_last': true,
    'mother_first': true,
    'mother_last': true,
    'insurance': true,
    'guarantor': true,
    'has_ffp_visit': true,
    'has_future_ffp_visit': true,
    'has_adoption': true
  }; 

  @Input("patient") patient: Patient = null;
  @Input("visits") visits: Visit[] = [];
  @Input("filterFields") filterFields: Boolean = false;
  @Input("hasButtonBack") hasButtonBack: Boolean = false;
  @Input("hasButtonEdit") hasButtonEdit: Boolean = false;
  @Input("hasButtonComplete") hasButtonComplete: Boolean = false;
  @Output('onButtonBack') onButtonBack: EventEmitter<Patient> = new EventEmitter<Patient>();
  @Output('onButtonEdit') onButtonEdit: EventEmitter<Patient> = new EventEmitter<Patient>();
  @Output('onButtonComplete') onButtonComplete: EventEmitter<any> = new EventEmitter<any>();
  
  constructor(
    private httpService: HttpService,
    private router: Router,
    private snackBarService: MatSnackBar,
    private loaderService: LoaderService,
  ) { 
    super();
  }

  ngOnInit(): void {
    this.fetchFields().subscribe( result => {
      if( this.filterFields )
      {
        this.fields.forEach( field => {
          if( this.displayFields.hasOwnProperty( field['field_name'] ))
          {
            this.displayFields[ field['field_name'] ] = field['display_field'];
          }
        });
      }
    });
  }

  ngOnChanges( changes: SimpleChanges ): void {

    if( changes.visits )
    {
      this.allVisitNotes = Visit.aggregateVisitNotes( this.visits );
      this.visits = Visit.mapToVisits( this.visits );
      this.previousVisitCptCodes = Visit.extractVisitCodesByType( this.visits, 'CPT' );
      this.previousVisitIcdCodes = Visit.extractVisitCodesByType( this.visits, 'ICD-10' );
    }

    // what to do when fields are loaded
    if( changes.fields && changes.fields.currentValue.length > 0 )
    {
      this.fields.forEach( field => {
        if( this.displayFields.hasOwnProperty( field['field_name'] ))
        {
          this.displayFields[ field['field_name'] ] = field['display_field'];
        }
      });
    }
  }

  // fetch active/inactive fields
  private fetchFields(): Observable<Field[]> {
    const urlApiFields = this.httpService.createUrl([
      'api',
      'fields'
    ], {}, {
      host: environment.host_api_default
    });

    let fieldObservable = this.httpService.get<Field[]>( urlApiFields, {} ).pipe(
      tap( _ => null ),
      map(( result: Field[] ) => result as Field[] ),
      map(( result ) => {
        this.fields = result;
        return result;
      })
    );
    return fieldObservable;
  }

  handleBack(): void {
    this.onButtonBack.emit( this.patient );    
  }

  handleEdit(): void {
    this.onButtonEdit.emit( this.patient );
  }

  handleComplete(): void {
    
    let chartValidator = HasChartValidator( this.httpService, this.patient.patient_id );
    this.loaderService.show();

    chartValidator.pipe( 
      takeUntil( this.appUnsubscribe ),
      mergeMap( r => {

        if ( !r ) {
          throw 'ERROR_NO_CHART';
        } else {

          let urlApiStatus: string = this.httpService.createUrl([
            'api',
            'statuses',
            'slugs',
            'COMPLETED'
          ], {}, { host: environment.host_api_default });

          let completedSlug = this.httpService.get<any>(urlApiStatus)
            .pipe( takeUntil( this.appUnsubscribe ))

          return completedSlug;
        }
      }),
      mergeMap( r => {
        if ( r.status_id > 0 ){

          let urlApiPatient: string = this.httpService.createUrl([
            'api',
            'patients',
            this.patient.patient_id
          ], {}, { host: environment.host_api_default} );

          let updatePatient = this.httpService.put<Patient>( urlApiPatient, { status_id: r.status_id }).pipe( takeUntil( this.appUnsubscribe ));
          return updatePatient;

        } else {
          throw 'ERROR_MISSING_STATUS_SLUG';
        }
      })
    ).subscribe({
      next: ( r ) => {
        this.onButtonComplete.emit()
        this.snackBarService.open(`Patient Completed!`, `Ok` , {
          duration: 3000
        });
      },
      error: ( e ) => {
        if( e == 'ERROR_NO_CHART' ){
          this.snackBarService.open(`Unable to complete patient: Chart is required.`, `Dismiss` , {
            duration: 5000
          });
        } else if ( e == 'ERROR_MISSING_STATUS_SLUG' ){
          this.snackBarService.open(`Unable to complete patient: COMPLETED status slug not found`, `Ok` , {
            duration: 5000
          });
        } else {
          this.snackBarService.open(`Unable to complete patient: ${e}`, `Ok` , {
            duration: 10000
          });
        }
        this.loaderService.hide(); 
      }, 
      complete: () => {
        this.loaderService.hide(); 
      }
    });
  }

  trackByChip(  index: number, item: any ): string {
    return item.code;
  } 

  openSnackBar(toolTip: string) {
    this.snackBarService.open(toolTip, 'Ok', {
      duration: 3000
    });
  }

}
