<mat-toolbar color="accent">
    <mat-toolbar-row>
      <span>
        {{ subTitle }}
      </span>
      <span class="spacer"></span>
      <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </mat-toolbar-row>
</mat-toolbar>
<div class="flex-container">
    <form [formGroup]="personForm" *ngIf="isFormLoaded()" (ngSubmit)="handleSubmit()" class="flex-item flex-container">

        <mat-form-field appearance="fill">
            <mat-label>First Name</mat-label>
            <input matInput name="first_name" formControlName="first_name" maxlength="50" />
            <mat-error *ngIf="personForm.controls['first_name'].hasError('required')">
                First name is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Last Name</mat-label>
            <input matInput name="last_name" formControlName="last_name" maxlength="50" />
            <mat-error *ngIf="personForm.controls['last_name'].hasError('required')">
                Last name is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Preferred Name</mat-label>
            <input matInput name="preferred_name" formControlName="preferred_name" maxlength="50" />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Title</mat-label>
            <input matInput name="title" formControlName="title" maxlength="50" />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input matInput name="email" formControlName="email" inputmode="email" required maxlength="50" />
            <mat-error *ngIf="personForm.controls['email'].hasError('email') && !personForm.controls['email'].hasError('required')">
                Valid email address required.
            </mat-error>
            <mat-error *ngIf="personForm.controls['email'].hasError('required')">
                Email is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Phone</mat-label>
            <input matInput name="phone" formControlName="phone" inputmode="tel" required maxlength="50" />
            <mat-error *ngIf="personForm.controls['phone'].hasError('pattern') && !personForm.controls['phone'].hasError('required')">
                Valid phone number required.
            </mat-error>
            <mat-error *ngIf="personForm.controls['phone'].hasError('required')">
                Phone is required.
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>User Name</mat-label>
            <mat-hint>The name with which the user will log in.</mat-hint>
            <input matInput name="username" formControlName="username" required maxlength="50" /> 
            <mat-error *ngIf="personForm.controls['username'].hasError('required')">
                User name is required.
            </mat-error>
        </mat-form-field>
        
        <button *ngIf="!isRegister" name="button-submit" class="bottom-margin" mat-raised-button type="button" color="primary" (click)="changePassword()">
            <mat-icon>lock</mat-icon> 
            <span>Reset Password</span> 
        </button>

        <mat-form-field appearance="fill" *ngIf="isRegister">
            <mat-label>Password</mat-label>
            <input matInput name="password" [type]="hidePassword ? 'password' : 'text'" formControlName="password" maxlength="100" minlength="8" />
            <button mat-icon-button matSuffix type="button" tabindex="-1" (click)="hidePassword = !hidePassword">
                <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="personForm.controls['password'].hasError('passwordStrength') && !personForm.controls['password'].hasError('minlength')">
                <div *ngFor="let error of personForm.controls['password'].errors | keyvalue">
                    {{ error.value }}
                </div>
            </mat-error>
            <mat-error *ngIf="personForm.controls['password'].hasError('minlength') && !personForm.controls['password'].hasError('required')">
                Minimum of 8 character required.
            </mat-error>
            <mat-error *ngIf="personForm.controls['password'].hasError('required')">
                Password is required.
            </mat-error>
        </mat-form-field>
        
        <mat-form-field appearance="fill" *ngIf="isRegister">
            <mat-label>Confirm Password</mat-label>
            <input matInput name="confirm_password" [type]="hidePassword ? 'password' : 'text'" formControlName="confirm_password" maxlength="100" />
            <mat-error *ngIf="personForm.controls['confirm_password'].hasError('confirmPassword')">
                Confirm password does not match password.
            </mat-error>
        </mat-form-field>
        
        <div class="field-container">
            <mat-slide-toggle formControlName="has_password_change" class="slider-toggle" >Require Password Change On Login</mat-slide-toggle>
        </div>
        
        <mat-toolbar color="accent">
            <mat-toolbar-row>
              <span>
                User Role
              </span>
            </mat-toolbar-row>
        </mat-toolbar>
        <mat-card>
            <mat-card-content>
                <mat-selection-list name="roles" formControlName="roles">
                    <mat-list-option *ngFor="let role of activeRoles" [value]="role.role_id">
                        {{ role.role_name }} {{ role.checked }}
                    </mat-list-option>
                    <mat-error *ngIf="personForm.controls['roles'].hasError('selectedRole')">
                        At least one user role must be selected.
                    </mat-error>
                </mat-selection-list>
            </mat-card-content>
        </mat-card>

        <div class="controls">
            <button name="button-submit" [disabled]="getIsSubmitActive()"  mat-raised-button type="submit" color="primary">Submit</button>
        </div>
    </form>
</div>