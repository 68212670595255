import { Component, OnInit, Input, DoCheck } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@app/services/auth.service';
import { LoaderService } from '@app/services/loader.service';
import { PageTitleService } from '@app/services/page-title.service';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeDialogComponent } from '@app/modules/admin/password-change-dialog/password-change-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from '@app/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../../../shared/globalstyles.css']
})
export class LoginComponent extends BaseComponent implements OnInit, DoCheck {
  
  // variables
  private isSubmitDisabled: boolean = true;

  @Input() error: string | null = null;

  form: UntypedFormGroup = new UntypedFormGroup({
    username: new UntypedFormControl('', { validators: Validators.required }),
    password: new UntypedFormControl('', { validators: Validators.required })
  });

  constructor(
    public dialogNotice: MatDialog,
    private router: Router,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private passwordDialog: MatDialog,
    private snackBarService: MatSnackBar) 
    {
      super();
  }

  // handle whether or not submit button should be active
  getIsSubmitActive(): boolean {
    return this.isSubmitDisabled;
  }

  ngOnInit(): void {
    this.pageTitleService.setPageTitle("Families First Pediatrics");

    // monitor form changes
    this.isSubmitDisabled = !this.form.valid;
    this.form.valueChanges
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( data => {
      this.isSubmitDisabled = !this.form.valid; // set form validity whenever values change
    });

  }

  changePassword(userId: number): void {
    const passDialog = this.passwordDialog.open(PasswordChangeDialogComponent, {
      width: '300px',
      disableClose: true,
      data: { user_id: userId, required: true, is_current_user: true }
    });

    passDialog.afterClosed()
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe(result => {
      this.snackBarService.open(`Password change ${result}`, `Ok`, {
        duration: 3000
      }).afterDismissed()
      .pipe( takeUntil( this.appUnsubscribe ) )
      .subscribe(() => {
        this.router.navigate(['/']);
      });
    });
  }

  ngDoCheck(): void {
  }

  submit() {
    this.loaderService.show();
    if (this.form.valid) {
      let loginData = this.form.value;
      this.authService.login(loginData['username'], loginData['password']).subscribe(result => {
        if (result.has_password_change) {
          this.isSubmitDisabled = true;
          this.changePassword(result.user_id);
        } else {
          this.router.navigate(['/']);
        }
      }, error => {
        const dialogReference = this.dialogNotice.open( LoginDialogNoticeComponent );
        // login failed, user not found
        console.log(error.message);
        this.loaderService.hide();
      }, () => {
        this.loaderService.hide();
      });

    }
  }
}



// dialog specific to this component
@Component({
  selector: 'login-dialog-notice',
  templateUrl: 'login-dialog-notice.html'
})
export class LoginDialogNoticeComponent {
}