import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '@app/base.component';
import { Hospital } from '@app/models/hospital';
import { Patient } from '@app/models/patient';
import { Field } from '@app/models/field';
import { Status } from '@app/models/status';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from '@app/services/http.service';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { LoaderService } from '@app/services/loader.service';
import { HasChartValidator } from '@app/library/validators.class';
import { P } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.css']
})
export class PatientFormComponent extends BaseComponent implements OnInit {

  // variables
  private isSubmitDisabled: boolean = true;
  fields: Field[] = [];
  formReady: boolean = false;
  patientHasChart: boolean = false;

  displayFields: any = {
    'baby_first': true,
    'baby_last': true,
    'mother_first': true,
    'mother_last': true,
    'insurance': true,
    'guarantor': true,
    'has_ffp_visit': true,
    'has_future_ffp_visit': true,
    'has_adoption': true
  }; 
  
  requireFields: any = {
    'baby_first': false,
    'baby_last': false,
    'mother_first': false,
    'mother_last': false,
    'insurance': false,
    'guarantor': false,
    'has_ffp_visit': false,
    'has_future_ffp_visit': false,
    'has_adoption': false
  }; 

  // inputs/outputs
  @Input("currentPatient") currentPatient: Patient = null;
  @Input("initializeTestData") initializeTestData: boolean = false;
  @Input("hospitals") hospitals: Hospital[] = [];
  @Input("statuses") statuses: Status[] = [];
  @Input("initialValues") initialValues: object = {};
  @Input("canDelete") canDelete: Boolean = false;
  @Input("filterFields") filterFields: Boolean = false;
  @Output() onSubmit: EventEmitter<Patient> = new EventEmitter<Patient>();

  // patient form
  patientForm = new UntypedFormGroup({
    dob: new UntypedFormControl((new Date()).toISOString(), Validators.required ),
    hospital_id: new UntypedFormControl( null, { validators: Validators.required }),
    status_id: new UntypedFormControl( null, { validators: Validators.required }),
    mother_first: new UntypedFormControl( null, {}),
    mother_last: new UntypedFormControl( null, {}),
    baby_first: new UntypedFormControl( null, {}),
    baby_last: new UntypedFormControl( null, {}),
    guarantor: new UntypedFormControl( null, {}),
    insurance: new UntypedFormControl( null, {}),
    has_ffp_visit: new UntypedFormControl( null, {}),
    has_future_ffp_visit: new UntypedFormControl( null, {}),
    sex: new UntypedFormControl( null, { validators: Validators.required })
  });

  // form field data
  sexes: Array<{id: string, name: string}> = [ 
    { id: 'M', name: 'Male' },
    { id: 'F', name: 'Female' }
  ];

  constructor( 
    private httpService: HttpService
  ){ 
    super();
  }

  // handle whether or not submit button should be active
  getIsSubmitActive(): boolean {
    return this.isSubmitDisabled;
  }

  // handle form submit
  handleSubmit(): void {
    this.onSubmit.emit(
      this.patientForm.value
    );
  }

  // load form with test data
  loadTestData(): void {
    this.patientForm.patchValue({
      hospital_id: 1,
      status_id: 1,
      baby_last: 'Babylast',
      baby_first: 'Babyfirst',
      mother_last: 'Motherlast',
      mother_first: 'Motherfirst',
      guarantor: 'Payment Guarantor',
      insurance: 'Insurance Company',
      has_ffp_visit: true,
      has_future_ffp_visit: true,
      sex: 'M'
    });
  }

  // happens whenever inbound data properties change
  ngOnChanges( changes: SimpleChanges ): void {
    // set hospital if one is passed to this component
    if( Object.keys( this.initialValues ).length !== 0 )
    {
      this.patientForm.patchValue( this.initialValues );
    }

    if( changes.currentPatient && changes.currentPatient.currentValue != null )
    {
      this.loadCurrentPatient();

      // verify patient chart
      HasChartValidator(this.httpService, this.currentPatient.patient_id)
      .pipe( takeUntil( this.appUnsubscribe ) )
      .subscribe(result => {
        this.patientHasChart = result;
      });
    }
  }

  // load up form with current patient values
  public loadCurrentPatient(): void {
    this.patientForm.setValue({
      dob: this.currentPatient['dob'],
      hospital_id: this.currentPatient['hospital_id'],
      status_id: this.currentPatient['current_status_id'],
      baby_last: this.currentPatient['baby_last'],
      baby_first: this.currentPatient['baby_first'],
      mother_last: this.currentPatient['mother_last'],
      mother_first: this.currentPatient['mother_first'],
      guarantor: this.currentPatient['guarantor'],
      insurance: this.currentPatient['insurance'],
      has_ffp_visit: this.currentPatient['has_ffp_visit'],
      has_future_ffp_visit: this.currentPatient['has_future_ffp_visit'],
      sex: this.currentPatient['sex']
    });
  }

  // fetch active/inactive fields
  private fetchFields(): Observable<Field[]> {
    const urlApiFields = this.httpService.createUrl([
      'api',
      'fields'
    ], {}, {
      host: environment.host_api_default
    });

    let fieldObservable = this.httpService.get<Field[]>( urlApiFields, {} ).pipe(
      tap( _ => null ),
      map(( result: Field[] ) => result as Field[] ),
      map(( result ) => {
        this.fields = result;
        return result;
      })
    );
    return fieldObservable;
  }

  // check for existence of validator on a field
  fieldIsRequired( fieldName: string ): boolean {
    let field = this.fields.filter( field => {
      return ( field['field_name'] == fieldName );
    });
    if( field.length > 0 )
    {
      return field[0]['require_field'];
    } else {
      return false;
    }
  }

  statusIsDisabled(field: string): boolean {
    if (field.toLowerCase() == "completed") {
      return !this.patientHasChart;
    } else {
      return false;
    }
  }

  // what to do when form initializes
  ngOnInit(): void {
    // check to see if patient has been passed to this form
    // use patchValue to update a single
    let dateToday = new Date();
    dateToday.setDate(dateToday.getDate() - 1);
    this.patientForm.patchValue({
      dob: dateToday.toISOString()
    });
   
    // load form with test data if module requires it
    if( this.initializeTestData )
    {
      console.log( '...initializing test data.' );
      this.loadTestData();
    }

    // monitor form changes
    this.isSubmitDisabled = !this.patientForm.valid;
    this.patientForm.valueChanges
      .pipe( takeUntil( this.appUnsubscribe ) )
      .subscribe( data => {
        this.isSubmitDisabled = !this.patientForm.valid; // set form validity whenever values change
    });

    // get active form fields
    this.fetchFields()
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      // do nothing
      if( this.filterFields )
      {
        this.fields.forEach( field => {
          if( this.displayFields.hasOwnProperty( field['field_name'] ))
          {
            this.displayFields[ field['field_name'] ] = field['display_field'];
            if( this.patientForm.contains( field['field_name'] ))
            {
              // modify form to required certain fields
              if( field['require_field'] == true )
              {
                this.patientForm.get( field['field_name'] ).setValidators( Validators.required );
                this.requireFields[ field['field_name'] ] = field['require_field'];
              } 
            }
          }
        });
      }
      this.formReady = true;
    });

  }

}
