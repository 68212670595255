<mat-sidenav-container class="sidenav-container" fullscreen>
  
  <mat-sidenav #drawer class="sidenav" fixedInViewport 
      [attr.role]="( isHandset$ | async ) ? 'dialog' : 'navigation'"
      [mode]="( isHandset$ | async ) ? 'over' : 'side'"
      [opened]="( displaySidebar$ | async ) && ( isHandset$ | async ) === false">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['']"><mat-icon class="menuicon">apartment</mat-icon>Hospitals</a>
      <a mat-list-item [routerLink]="['patient/list']"><mat-icon class="menuicon">assignment_ind</mat-icon>Patients</a>
      <a mat-list-item [routerLink]="['patient/add']"><mat-icon class="menuicon">local_hospital</mat-icon>Add Patient</a>
      <a *ngIf="getUserIsAdmin() || getUserIsBilling()" mat-list-item [routerLink]="['billing/patients']"><mat-icon class="menuicon">paid</mat-icon>Billing</a>
      <!-- <a *ngIf="getUserIsAdmin()" mat-list-item [routerLink]="['administration/users']" [matMenuTriggerFor]="administration"><mat-icon class="menuicon">security</mat-icon>Admin</a> -->
      <a *ngIf="getUserIsAdmin()" mat-list-item (click)="displayMenuAdministration=!displayMenuAdministration"><mat-icon class="menuicon">security</mat-icon>Admin</a>

      <mat-nav-list class="nav-child" #menuAdministration *ngIf="displayMenuAdministration">
        <a mat-list-item [routerLink]="['administration/users']"><mat-icon class="menuicon">people</mat-icon>Users</a>
        <a mat-list-item [routerLink]="['administration/fields']"><mat-icon class="menuicon">format_list_bulleted</mat-icon>Fields</a>
      </mat-nav-list>

      <a mat-list-item [routerLink]="['account/user']"><mat-icon class="menuicon">account_circle</mat-icon>Account</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <mat-toolbar-row style="padding-left: 6px; padding-right: 0px;">
        <img style="height: 32px; margin: 0 8px;" src="assets/images/ffpeds-logo.png">
        <span>{{getPageTitle()}}</span>
        <span class="spacer"></span>
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="( displaySidebar$ | async ) && ( isHandset$ | async )">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <button [matMenuTriggerFor]="menu" *ngIf="displaySidebar$ | async"
          type="button"
          aria-label="User Account"
          mat-icon-button>
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="handleButtonAccount()">
            <mat-icon>face</mat-icon>
            <span>Account</span>
          </button>
          <button mat-menu-item (click)="handleButtonLogout()">
            <mat-icon>logout</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </mat-toolbar-row>
    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
