import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteDataService {

  private routeData: BehaviorSubject<{[k: string]: any}> = new BehaviorSubject<{[k: string]: any}>({}); // persistent cache data
  public routeData$: Observable<{[k: string]: any}> = this.routeData.asObservable();
  private previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  constructor(
  ) { 
    // setup all persistent data
  }

  setPreviousUrl( previousUrl: string ): void {
    this.previousUrl.next( previousUrl );
  }

  clearRouteData(): void {
    this.routeData.next({
      'codes': [],
      'hospitals': [],
      'statuses': [],
      'physicians': []
    });
  }

  setRouteData( data: any, key: string ): void {
    let currentValue = this.routeData.getValue();
    // console.log( 'RouteDataService.setRouteData(): ', currentValue );
    currentValue[key] = data;
    this.routeData.next(currentValue);
  }

}
