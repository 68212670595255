import { Component, Input, OnInit, EventEmitter, Output, ViewChild, AfterViewInit, OnChanges, ElementRef, AfterViewChecked, SimpleChanges } from '@angular/core';
import { Patient } from '@app/models/patient';
import { MatSort } from '@angular/material/sort';
import { animate, state, style, transition, trigger } from '@angular/animations'
import { ThemePalette } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { Status } from '@app/models/status';
import { BaseComponent } from '@app/base.component';
import { LoaderService } from '@app/services/loader.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', 
      animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class PatientListComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  // local variables
  patientData: MatTableDataSource<Patient> = new MatTableDataSource<Patient>([]);
  selectedStatus: Status = new Status();

  // @Input() onRowEdit: Function;
  @Input('patients') patients: Patient[] = [];
  @Input('statuses') statuses: Status[] = [];
  @Input('hasData') hasData: boolean = false;
  @Input('hasButtonBack') hasButtonBack: boolean = false;
  @Input('hasButtonArchive') hasButtonArchive: boolean = false;
  @Input('hasButtonStatus') hasButtonStatus: boolean = false;
  @Input('hasButtonAdd') hasButtonAdd: boolean = false;
  @Input('hasDaysSinceBirth') hasDaysSinceBirth: boolean = false;
  @Input('displayMother') displayMother: boolean = false;
  @Input('displayChild') displayChild: boolean = true;
  @Output('onRowEdit') onRowEdit: EventEmitter<Patient> = new EventEmitter<Patient>();
  @Output('onRowView') onRowView: EventEmitter<Patient> = new EventEmitter<Patient>();
  @Output('onBack') onBack: EventEmitter<any> = new EventEmitter<any>();
  @Output('onPatientAdd') onPatientAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output('onButtonArchive') onButtonArchive: EventEmitter<any> = new EventEmitter<any>();

  // viewchild objects
  @ViewChild( MatSort ) sort: MatSort;
  @ViewChild( 'filter' ) filter: ElementRef;

  columnsToDisplay: string[] = ['view', 'hospital_name', 'current_status_name', 'dob', 'sex' ];
  columnsHandset: string[] = ['view', 'hospital_name', 'dob'];
  columnsFull: string[] = [];
  expandedElement: any | null;
  themeColor: ThemePalette = "primary";
  isHandset$: Observable<boolean> = this.breakpointObserver.observe( Breakpoints.Handset )
    .pipe(
      map( result => result.matches ),
      shareReplay()
    );

  constructor(
    private loaderService: LoaderService,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  // initialization of component
  ngOnInit(): void {
    if( this.displayMother == true ){ 
      this.columnsToDisplay.splice(1, 0, 'mother_last');
      this.columnsHandset.splice(1, 0, 'mother_last'); 
    }
    if( this.displayChild == true ){ 
      this.columnsToDisplay.splice(1, 0, 'baby_last');
      if ( !this.displayMother ) {
        this.columnsHandset.splice(1, 0, 'baby_last');
      }
    }
    if( this.hasDaysSinceBirth == true){
      let dobIndex = this.columnsToDisplay.findIndex(item => item == "dob"); 
      this.columnsToDisplay.splice(dobIndex + 1, 0, 'days_since_birth'); 
    }
    this.columnsFull = this.columnsToDisplay;
    this.isHandset$
      .pipe( takeUntil( this.appUnsubscribe ) )
      .subscribe( result => {
        if (result) {
          this.columnsToDisplay = this.columnsHandset;
        } else {
          this.columnsToDisplay = this.columnsFull;
        }
      })
  }

  // when input/output data changes
  ngOnChanges( changes: SimpleChanges ): void {

    if( this.patients )
    {
      if( this.hasButtonStatus === true )
      {
        if( this.statuses.length > 0 )
        {
          this.selectedStatus = this.statuses[0];  
          this.filterPatientData();
        }
      } else {
        this.filterPatientData();
      }
    }
  }
  
  // filter patient data
  private filterPatientData(): void {
    
    //  add hospital name to patient data
    this.patients.map( item => {
      item["hospital_name"] = item.hospital.name;
      return item;
    });

    // this.loaderService.show();
    let patients = this.patients;

    if( this.hasButtonStatus === true )
    {
      patients = patients.filter( patient => {
        return patient.current_status_id == this.selectedStatus.status_id;
      });
    }
    this.patientData = new MatTableDataSource<Patient>( patients );
    this.patientData.sort = this.sort;
    // this.loaderService.hide();
  }

  // when status drop-down changes
  onChangeStatus( event: any ): void {
    this.selectedStatus = event.value;
    this.filterPatientData();
  }

  getDaysSinceBirth(dob: string): Number {
    let birthDate = new Date(dob);
    let today = new Date();
    return Math.round((today.getTime() - birthDate.getTime()) / (1000 * 3600 * 24));
  }

  // after view has initialized
  ngAfterViewInit(): void {
    // when using after view init, for some reason you have to run a slight delay before calling focus
    setTimeout(() => {
      this.filter.nativeElement.focus();
    }, 100);
  }
  
  // after angular checks the component's views
  ngAfterViewChecked(): void {
  }

  // what to do when table filter is applied
  handleFilter( event: any ): void {
    let text = event.target.value;
    this.patientData.filter = text.trim().toLocaleLowerCase();
  }

  // what happens when back button is clicked
  handleBack(): void {
    this.onBack.emit();
  }

  handlePatientAdd(): void {
    this.onPatientAdd.emit( null );
  }

  // action when edit button is clicked
  handleRowEdit( element: Patient ): void {
    this.onRowEdit.emit( element );
  }

  // what to do when view button is clicked on
  handleRowView( element: Patient ): void {
    this.onRowView.emit( element );
  }

  // what to do when view button is clicked on
  handleButtonArchive(): void {
    this.onButtonArchive.emit();
  }

  rowExpand( element: any ) {
    element.isExpanded = true;
    if (this.expandedElement === element) {
      element.isExpanded = false;
      this.expandedElement = null;
    } else {
      if (this.expandedElement)
        this.expandedElement.isExpanded = false;
      this.expandedElement = element;
    }
  }
}
