import { Role } from "./role";
import { Visit } from "./visit";

export class User {
    username: string;
    user_id: number;
    person_id?: number;
    physician_id?: number;
    email: string;
    title?: string;
    first_name: string;
    last_name: string;
    preferred_name?: string;
    phone?: string;
    has_password_change: boolean;
    is_active: boolean;
    roles: Role[];
    visits: Visit[];
    token: string;

    /**
     * Returns a test user account.
     */
    public static getTestUser(): User {
        let user = new User();
        user.username = 'testuser';
        user.user_id = 1;
        user.person_id = 1;
        user.physician_id = 1;
        user.email = 'testuser@test.net';
        user.first_name = 'Test';
        user.last_name = 'User';
        user.preferred_name = 'Test User';
        user.phone = '123-123-1234';
        user.is_active = true;
        user.roles = [
            {
                role_id: 1,
                role_name: 'admin',
            },
            {
                role_id: 2,
                role_name: 'provider',
            },
            {
                role_id: 3,
                role_name: 'billing',
            }
        ];
        return user;
    }
}
