import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { User } from '@app/models/user';
import { HttpService } from './http.service';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from '@env/environment';
import { Router, UrlTree } from '@angular/router';
import { switchMap, map, catchError, tap, filter, take } from 'rxjs/operators';
import { RouteDataService } from './route-data.service';
// https://jasonwatmore.com/post/2019/08/06/angular-8-role-based-authorization-tutorial-with-example

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUser: BehaviorSubject<User> = new BehaviorSubject( null );
  public currentUser$: Observable<User> = this.currentUser.asObservable();
  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject( false );
  public isAuthenticated$: Observable<boolean> = this.isAuthenticated.asObservable();
  private urlLogin: UrlTree;

  // constructor
  constructor(
    private routeDataService: RouteDataService,
    private httpService: HttpService,
    private router: Router,
    private http: HttpClient
  ) { 

    // set the login url ( we use this in other modules/components when we need to route to the login page )
    this.urlLogin = this.router.createUrlTree([ 'account', 'login' ]);
    this.loginEnvironment();
  }
  
  private loginEnvironment(){
    if( environment.bypass_login == true && environment.production == false )
    {
      this.currentUser.next( User.getTestUser());
      this.isAuthenticated.next( true );
    }
  }

  // get the login url
  public getUrlLogin(): UrlTree {
    return this.urlLogin;
  }

  // login via api
  public login( username: string, password: string ) {
    const urlApi = this.httpService.createUrl([
      'auth',
      'login'
    ], {}, { host: environment.host_api_default });
    return this.httpService.post<any>( urlApi, { username, password }, {
       //observe: 'response' as 'response'
       withCredentials: true
    }).pipe(
      map( authResult => {
        // login successful if there's a token in the response
        if( authResult && authResult.user) {
          this.currentUser.next( authResult.user );
          this.isAuthenticated.next( true );
        }
        return authResult.user;
      })
    )
  }

  // logout the user
  public logout(): void {

    // remove user from local storage to logout
    localStorage.removeItem('currentUser');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    this.routeDataService.clearRouteData();
    this.currentUser.next( null );
    this.isAuthenticated.next( false ); // tell all observers that user is no longer authenticated
  }

  public getAccessToken(): string {
    return 'token';
  }

  public refreshToken() {
    const urlApi = this.httpService.createUrl([
      'auth',
      'refresh_token'
    ], {}, { host: environment.host_api_default });

    const httpOptions = {
      withCredentials: true
    };

    return this.httpService.post<any>(urlApi, {}, httpOptions).pipe(
      tap(data => {
        let user = this.currentUser.getValue();
        if( data.token )
        {
          user.token = data.token;
          this.currentUser.next(user);
        } else {
          this.logout();
        }
      })
    );
  }

  /**
   * Check to see if user has role from string of roles.
   * 
   * @param roles array of string roles to check against
   */
  public userHasRole( roles: string[] )
  {
    let hasRole: boolean = false;
    let user = this.getCurrentUser();

    if (user != null) {
      let userRoles = user.roles;
      userRoles.forEach( role => {
        if( roles.indexOf( role.role_name ) !== -1 )
        {
          hasRole = true;
        }
      });
    }
    return hasRole;
  }

  // get the current user
  public getCurrentUser(): User {
    return this.currentUser.getValue();
  }

  public userIsAuthenticated(): boolean {
    return this.isAuthenticated.getValue();
  }

  public setUserAuthenticated(isAuthenticated: boolean) : void {
    this.isAuthenticated.next( isAuthenticated );
  }
  
}



@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  
  constructor(private authService: AuthService) {
  }
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private addToken(req: HttpRequest<any>, token: string) {
    return req.clone({
      headers: req.headers.set('x-access-token', token)
    });
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((data: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(data.token);
          return next.handle(this.addToken(req, data.token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(data => data != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addToken(req, token));
        })
      );
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.userIsAuthenticated() && !this.isRefreshing) {
      let user: User = this.authService.getCurrentUser();
      if (user && user.token) {
        req = this.addToken(req, user.token);
      }
      return next.handle(req).pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            switch (err.status) {
              case 401:
                return this.handle401Error(req, next);
              default:
                return throwError(err);
            }
            
          }
          else {
            return throwError(err);
          }
        })
      );      
    } else {
      return next.handle(req);
    }
  }
}     