import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule, Routes } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { UserEditComponent } from './user-edit/user-edit.component';
import { UserSelectComponent } from './user-select/user-select.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PasswordChangeDialogComponent } from './password-change-dialog/password-change-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FieldSelectComponent } from './field-select/field-select.component';
import { SharedModule } from '@app/shared/shared.module';
import { FieldEditComponent } from './field-edit/field-edit.component';

const routes: Routes = [
  {
    path: 'register',
    component: UserEditComponent
  },
  {
    path: 'users/:person_id/edit',
    component: UserEditComponent
  },
  {
    path: 'users/:person_id',
    component: UserDetailComponent
  },
  {
    path: 'users',
    component: UserSelectComponent
  },
  {
    path: 'fields',
    component: FieldSelectComponent
  },
  {
    path: 'fields/edit/:application_field_id',
    component: FieldEditComponent
  }
]

@NgModule({
  declarations: [
    UserEditComponent, 
    UserSelectComponent, 
    UserDetailComponent, 
    PasswordChangeDialogComponent, 
    FieldSelectComponent, FieldEditComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatOptionModule,
    MatCheckboxModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatToolbarModule,
    MatTableModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatSnackBarModule,
    SharedModule
  ]
})
export class AdminModule { }
