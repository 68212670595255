<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>Patient</span>
        <span class="spacer"></span>
        <span>
            <button type="button" aria-label="Go Back" mat-raised-button (click)="handleBack()">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </span>
        <span>
            <button type="button" aria-label="Go Back" color="warn" mat-raised-button (click)="handleButtonDelete()">
                <mat-icon>delete_outline</mat-icon>
            </button>
        </span>
    </mat-toolbar-row>
</mat-toolbar>

<app-patient-form 
    (onSubmit)="onSubmit( $event )" 
    [currentPatient]="patient" 
    [hospitals]="hospitals" 
    [filterFields]="true" 
    [statuses]="statuses">
</app-patient-form>

