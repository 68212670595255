import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { BaseComponent } from '@app/base.component';
import { Person } from '@app/models/person';
import { HttpService } from '@app/services/http.service';
import { PageTitleService } from '@app/services/page-title.service';
import { environment } from '@env/environment';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent extends BaseComponent implements OnInit {
  
  urlBack: string = null;
  subTitle: string = "User Details";
  person: Person = new Person();
  isLoaded: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private appComponent: AppComponent,
    private pageTitleService: PageTitleService) { 
      super();
    }

  ngOnInit(): void {
    this.urlBack = this.router.createUrlTree([ '/administration/users' ]).toString();
    this.appComponent.appendSiteTitle('Users', 'Detail');
    this.pageTitleService.setPageTitle('Users Administration');

    this.activatedRoute.params
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe(params => {
      if (params.person_id) {
        this.fetchPerson(params.person_id);
      }
    });
  }

  fetchPerson(person_id: number) {
    let urlApi: string = this.httpService.createUrl([
      'api',
      'users',
      person_id
    ], {}, { host: environment.host_api_default});

    this.httpService.get<Person>(urlApi).pipe(
      tap( _ => null )
    ).subscribe(data => {
      this.person = data;
    }, () => {
      // do nothing
    }, () => {
      this.isLoaded = true;
    });
  }

  handleEdit(): void {
    this.router.navigate(['administration', 'users', this.person.person_id, 'edit']);
  }
  
  handleBack(): void {
    this.router.navigateByUrl( this.urlBack );
  }
}
