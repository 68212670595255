import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { PageTitleService } from '@app/services/page-title.service';
import { AuthService } from '@app/services/auth.service';
import { HttpService } from '@app/services/http.service';
import { Router, NavigationEnd } from '@angular/router';
import { BaseComponent } from '@app/base.component';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent extends BaseComponent implements OnInit, OnDestroy {
  
  displayMenuAdministration: boolean = false;
  pageTitle: string = '';
  
  // observable menus
  displaySidebar$: Observable<boolean> = this.authService.isAuthenticated$;
  isHandset: boolean;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe( Breakpoints.Handset )
  .pipe(
    map(result => result.matches),
    shareReplay()
  );
  
  // html children elements
  @ViewChild('drawer') drawer: MatSidenav;
  
  constructor(
    private breakpointObserver: BreakpointObserver,
    private pageTitleService: PageTitleService,
    private authService: AuthService,
    private router: Router,
    private httpService: HttpService
  ) {
    super();
  }

  ngOnInit(): void {

    // close the drawer whenever a new router page is shown
    this.router.events.pipe(
      takeUntil( this.appUnsubscribe )
    ).subscribe( event => {
      if( event instanceof NavigationEnd && this.isHandset )
      {
        this.drawer.close();
      }
    });

    this.isHandset$
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe(result => {
      if (result) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    })
  }

  getPageTitle(): string {
    return this.pageTitleService.getPageTitle();
  }

  getUserIsAuthenticated(): boolean {
    return this.authService.userIsAuthenticated();
  }

  getUserIsAdmin(): boolean {
    return this.authService.userHasRole(['admin']);
  }

  getUserIsBilling(): boolean {
    return this.authService.userHasRole(['billing']);
  }

  // handle click of account button
  handleButtonAccount(): void {
    const urlAccount = this.httpService.createUrl([
      'account',
      'user'
    ]);
    this.router.navigateByUrl( urlAccount );
  }
  
  // handle click of logout
  handleButtonLogout(): void {
    this.authService.logout();
  }
}
