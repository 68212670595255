<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>Patients</span>
        <span class="spacer"></span>
        <ng-container *ngIf="hasButtonBack">
            <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
                <mat-icon>chevron_left</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="hasButtonAdd">
            <button type="button" aria-label="Go Back" mat-raised-button (click)="handlePatientAdd()">
                <mat-icon>add</mat-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="hasButtonStatus">
            <button mat-raised-button type="button" aria-label="Edit">
                <mat-select [(value)]="selectedStatus" (selectionChange)="onChangeStatus( $event )" style="width: 80px; text-align: left;">
                    <mat-option *ngFor="let status of statuses" [value]="status">{{ status.status_name }}</mat-option>
                </mat-select>
            </button>
        </ng-container>
        <ng-container *ngIf="hasButtonArchive">
            <button type="button" aria-label="Archive" mat-raised-button (click)="handleButtonArchive()">
                <mat-icon>folder_open</mat-icon>
            </button>
        </ng-container>
    </mat-toolbar-row>
</mat-toolbar>

<div class="flex-container">
    <form class="flex-item flex-container">
        <mat-form-field appearance="standard" tabindex="0">
            <input #filter matInput type="text" (keyup)="handleFilter( $event )" placeholder="Filter records..." />
        </mat-form-field>
    </form>
</div>

<table mat-table matSortDirection="asc" matSortActive="desc" [dataSource]="patientData" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="view">
        <th mat-header-cell class="mat-header-text" *matHeaderCellDef>View</th>
        <td mat-cell class="mat-cell-button" *matCellDef="let element">
            <button mat-mini-fab color="white" aria-label="Example icon button with a menu icon"
                [class.example-expanded-row]="expandedElement === element">
                <!-- <mat-icon>{{ element.isExpanded ? 'visibility_off' : 'visibility' }}</mat-icon> -->
                <mat-icon>visibility</mat-icon>
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="mother_last">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Mother</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> {{ ( element['mother_last'] ? element['mother_last'] : '' ) +
        ( element['mother_first'] ? ', ' + element['mother_first'] : '' ) }}</td>
    </ng-container>
    
    <ng-container matColumnDef="baby_last">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Child</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> {{ ( element['baby_last'] ? element['baby_last'] : '' ) +
          ( element['baby_first'] ? ', ' + element['baby_first'] : '' ) }}</td>
    </ng-container>
    
    <ng-container matColumnDef="current_status_name">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> {{ element.current_status_name }}</td>
    </ng-container>

    <ng-container matColumnDef="dob">
        <th mat-header-cell class="mat-header-text mat-cell-dob" *matHeaderCellDef mat-sort-header>DOB</th>
        <td mat-cell class="mat-cell-text mat-cell-dob" *matCellDef="let element"> {{ element.dob | date }}</td>
    </ng-container>

    <ng-container matColumnDef="days_since_birth">
        <th mat-header-cell class="mat-header-text mat-cell-days-since-header" *matHeaderCellDef mat-sort-header>Days</th>
        <td mat-cell class="mat-cell-text mat-cell-days-since" *matCellDef="let element"> {{ getDaysSinceBirth(element.dob) }}</td>
    </ng-container>

    <ng-container matColumnDef="hospital_name">
        <th mat-header-cell class="mat-header-text mat-cell-name" *matHeaderCellDef mat-sort-header>Hospital</th>
        <td mat-cell class="mat-cell-text mat-cell-name" *matCellDef="let element"> {{ element.hospital_name }}</td>
    </ng-container>

    <ng-container matColumnDef="sex">
        <th mat-header-cell class="mat-header-text mat-cell-sex" *matHeaderCellDef mat-sort-header>Sex</th>
        <td mat-cell class="mat-cell-text mat-cell-sex" *matCellDef="let element"> {{ element.sex }}</td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row" (click)="handleRowView( element )"></tr>
</table>