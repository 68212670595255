import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '@app/services/http.service';
import { PasswordStrengthValidator, ConfirmPasswordValidator } from '../../../library/validators.class';
import { environment } from '@env/environment';


@Component({
  selector: 'app-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.css']
})
export class PasswordChangeDialogComponent implements OnInit {

  passwordForm: UntypedFormGroup = new UntypedFormGroup({});
  hidePassword: boolean = true;
  required: boolean = false;
  isCurrentUser: boolean = false;
  result: string = '';

  constructor(
    private httpService: HttpService,
    public dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user_id: number, required: false, is_current_user: false }
  ) {
    this.passwordForm.addControl('password', new UntypedFormControl(null, { validators: [Validators.required, PasswordStrengthValidator] }));
    this.passwordForm.addControl('confirm_password', new UntypedFormControl(null, { validators: [Validators.required, (control => ConfirmPasswordValidator(control, this.passwordForm))] }));
    this.required = this.data.required;
    this.isCurrentUser = this.data.is_current_user;
  }

  ngOnInit(): void {
  }

  getIsSubmitActive(): boolean {
    return !this.passwordForm.valid;
  }

  handleSubmit(): void {
    let urlPath = this.isCurrentUser ? ['api', 'users', this.data.user_id, 'changepass'] : ['admin', 'users', this.data.user_id, 'changepass'];
    const urlApi = this.httpService.createUrl(urlPath, {}, { host: environment.host_api_default});

    let update = this.httpService.put<any>(urlApi, { 
      user_id: this.data.user_id, 
      password: this.passwordForm.value.password 
    });
    update.subscribe(result => {
      if (result) {
        this.dialogRef.close('succesful!');
      } else {
        this.dialogRef.close('failed!');
      }
    }, err => {
      this.dialogRef.close('failed!');
    }); 
  }

  cancelDialog(): void {
    this.dialogRef.close(null);
  }
}
