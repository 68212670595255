<mat-toolbar color="accent">
    <mat-toolbar-row>
      <span>
        Select a User
      </span>
      <span class="spacer"></span>
      <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button type="button" aria-label="Go Back" mat-raised-button (click)="handleUserAdd()">
        <mat-icon>add</mat-icon>
      </button>
    </mat-toolbar-row>
</mat-toolbar>
<table mat-table matSortDirection="asc" matSortActive="desc" [dataSource]="userData" matSort class="mat-elevation-z8">
  
    <ng-container matColumnDef="edit">
      <th mat-header-cell class="mat-header-text" *matHeaderCellDef>Edit</th>
      <td mat-cell class="mat-cell-button" *matCellDef="let element">
        <button mat-mini-fab color="white" aria-label="Example icon button with a menu icon"
          (click)="handleRowEdit( element )">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>
   
  
    <ng-container matColumnDef="user_name">
      <th mat-header-cell class="mat-cell-name" *matHeaderCellDef>Name</th>
      <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
          {{ (element['title'] ? element['title'] + '&nbsp;' : '') + element['last_name'] + ', ' + element['first_name'] }}
    </td>
    </ng-container>
  
    <ng-container matColumnDef="phone">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef>Phone</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
          {{ element['phone'] }}
        </td>
    </ng-container>

    <ng-container matColumnDef="email">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef>Email</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
          {{ element['email'] }}
        </td>
    </ng-container>
  
    <ng-container matColumnDef="roles">
      <th mat-header-cell class="mat-header-text" *matHeaderCellDef>Role</th>
      <td mat-cell class="mat-cell-text" *matCellDef="let element"> 
        <span class="role-container" *ngFor="let role of element['roles']">
            {{ role.role_name }}
        </span>  
      </td>
    </ng-container>

    <ng-container matColumnDef="visits">
      <th mat-header-cell class="mat-header-text" *matHeaderCellDef>#Visits</th>
      <td mat-cell class="mat-cell-visits" *matCellDef="let element"> 
          <ng-container *ngIf="element.visits">
            {{ element.visits.length }}
          </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
  </table>

