import { Renderer2, Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { HttpService } from '@app/services/http.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Patient } from '@app/models/patient';
import { environment } from '@env/environment';
import { LoaderService } from '@app/services/loader.service';
import { BaseComponent } from '@app/base.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCompressor } from '@app/services/image-compressor.service';

@Component({
  selector: 'app-patient-chart',
  templateUrl: './patient-chart.component.html',
  styleUrls: ['./patient-chart.component.css']
})
export class PatientChartComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {

  @Input('patient') patient: Patient = null;
  @Input('hasButtonUpload') hasButtonUpload: Boolean = false;
  @Input('hasNewPatient') hasNewPatient: Boolean = false;
  @Output('onButtonBack') onButtonBack: EventEmitter<any> = new EventEmitter<any>();
  @Output('onButtonUpload') onButtonUpload: EventEmitter<any> = new EventEmitter<any>();
  @Output('onButtonSkip') onButtonSkip: EventEmitter<any> = new EventEmitter<any>();

  chartSubscription: Subscription;
  chartImage: any = null;
  isChartLoaded: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private httpService: HttpService,
    private loaderService: LoaderService,
    private domSanitizer: DomSanitizer,
    private imageCompressor: ImageCompressor
  ) { 
    super();
  }

  // initialize component
  ngOnInit(): void {
  }

  // check for input changes
  ngOnChanges( changes: SimpleChanges ): void {
    if( changes.patient.currentValue && changes.patient.previousValue == null ) {
      this.chartSubscription = this.fetchChart().pipe(
        takeUntil( this.appUnsubscribe )
      ).subscribe( result => {
        // do nothing
      });
    }
  }

  // fetch patient charts
  private fetchChart(): Observable<any> {
    const urlApiChart = this.httpService.createUrl([
      'api',
      'patients',
      this.patient.patient_id,
      'chart'
    ], {}, {
      host: environment.host_api_default
    });

    let chartObservable = this.httpService.get<any>( urlApiChart, {
      responseType: 'blob'
    }).pipe(
      tap( _ => null ),
      map(( result ) => {
        if( result.type === 'application/json' ) {
          // api returned json instead of image blob, this happens when the image is missing or some other error occurs
        } else {
          const blob = new Blob([result], { type: 'image/jpeg' });
          this.chartImage = this.domSanitizer.bypassSecurityTrustResourceUrl( window.URL.createObjectURL( blob));
        }
        this.isChartLoaded = true;
        return result;
      })
    );
    return chartObservable;
  }

  handleSkipStep(): void {
    this.onButtonSkip.emit();
  }

  // upload chart cloud storage
  // https://stackoverflow.com/questions/48746467/upload-image-to-web-api-2-using-angular-5
  handleAddChart( event: any ): void {
    
    let imageTypes = [
      'image/jpeg',
      'image/gif',
      'image/png',
      'image/webp'
    ];

    const urlChartAdd = this.httpService.createUrl([
      'api',
      'patients',
      this.patient.patient_id,
      'chart'
    ], 
      {},
      { host: environment.host_api_default }
    );

    // get files from file input
    let files: FileList = event.target.files;
    let file = files.item(0);
    let formData: FormData = new FormData();
    let imageFile: string = '';
    let imageOrientation: any = null;

    if( !imageTypes.includes( file.type ) )
    {
      this.openSnackBar('Error: You may only upload image files.');
      return;
    }

    // start processing image
    this.imageCompressor.uploadFile( event ).then(({ image, orientation }) => {
      imageFile = image;
      imageOrientation = orientation;
      return this.imageCompressor.imageData( imageFile );
    }).then(( image ) => {

      // find out the size of of the image, we need to shrink it down to a 
      // file that's no greater than 2400px tall or wide
      let compressionScale: number = 100;
      let maxWidthHeight: number = 2400;
      let maxValue: number = Math.max( image.naturalWidth, image.naturalWidth );

      // if the width or height is greater than maxWidthHeight, set the scale
      // to reduce it down to the maximum allowed
      if( maxValue > maxWidthHeight )
      {
        compressionScale = parseFloat(Math.round((maxWidthHeight/maxValue) * 100).toFixed(2));
      }
      
      return this.imageCompressor.compressFile( imageFile, imageOrientation, compressionScale, 100 );
    }).then(( image ) => {
      // upload image to remote server
      formData.append( 'chart', image.blob, 'temp' );

      this.loaderService.show();
      let update = this.httpService.post( urlChartAdd, formData );
      update.subscribe( result => {
        this.fetchChart().pipe(
          takeUntil( this.appUnsubscribe )
        ).subscribe( result => {
          this.loaderService.hide();
          this.onButtonUpload.emit();
        });
      });
  
    }).catch(( e ) => {
      console.error( e );
    }).finally(() => {
      // what to do when promise ends
    });
  }

  /**
   * Generic tooltip function.
   * 
   * @param toolTip 
   */
  openSnackBar(toolTip: string) {
    this.snackBar.open(toolTip, 'Ok', {
      duration: 6000
    });
  }



}
