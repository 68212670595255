import { HttpClient } from "@angular/common/http";
import { AbstractControl, UntypedFormGroup, ValidationErrors } from "@angular/forms"
import { HttpService } from "@app/services/http.service";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

export const PasswordStrengthValidator = function (control: AbstractControl): ValidationErrors | null {

  let value: string = control.value || '';

  if (!value) {
    return null
  }

  let upperCaseCharacters = /[A-Z]+/g
  if (upperCaseCharacters.test(value) === false) {
    return { passwordStrength: `Pasword must contain at least one upper case character.` };
  }

  let lowerCaseCharacters = /[a-z]+/g
  if (lowerCaseCharacters.test(value) === false) {
    return { passwordStrength: `Password must contain at least one lower case character.` };
  }

  let numberCharacters = /[0-9]+/g
  if (numberCharacters.test(value) === false) {
    return { passwordStrength: `Password must contain at least one number.` };
  }

  let specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
  if (specialCharacters.test(value) === false) {
    return { passwordStrength: `Password must contain at least one special character (ie ?!$@#...)` };
  }
  return null;
}

export const ConfirmPasswordValidator = function (control: AbstractControl, group: UntypedFormGroup): ValidationErrors | null {

  let confirmPassword = control.value;
  let password = group.controls['password'].value;

  if (password !== confirmPassword) {
    return { confirmPassword: `Confirm password does not match password.` };
  }
  return null;
}

export const SelectedRoleValidator = function (control: AbstractControl): ValidationErrors | null {
  let selectedRole: boolean = false;
  if (!control.dirty) return null;
  if (control.value) {
    control.value.forEach(cntrl => {
      if (cntrl) {
        selectedRole = true;
      }
    });
  }

  return selectedRole ? null : { selectedRole: `At least one user role must be selected`};
}

export const HasChartValidator = function (httpService: HttpService, patientId: Number): Observable<boolean> {
  const urlApiChart = httpService.createUrl([
    'api',
    'patients',
    patientId,
    'chart-exists'
  ], {}, {
    host: environment.host_api_default
  });
  return httpService.get<boolean>( urlApiChart ).pipe(
    map( (result: boolean) => result as boolean),
    map(( result ) => {
      return result;
    })
  );
}