<div class="mat-elevation-z4">
  <mat-toolbar color="accent">
    <mat-toolbar-row>
      <span>Visits</span>
      <span class="spacer"></span>
      <ng-container *ngIf="hasButtonAdd">
        <button mat-raised-button type="button" color="white" aria-label="Go Back" (click)="handleAddVisit()">
          <mat-icon>add</mat-icon>
        </button>
      </ng-container>
    </mat-toolbar-row>
  </mat-toolbar>

  <table mat-table [dataSource]="visits" multiTemplateDataRows width="100%">
    <ng-container matColumnDef="action_select">
      <th mat-header-cell *matHeaderCellDef></th>
      <td class="button-action" mat-cell *matCellDef="let element">
        <button mat-mini-fab color="white" aria-label="icon button with a view icon" (click)="handleViewVisit( element )">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="physician">
      <th mat-header-cell class="mat-cell-phys" *matHeaderCellDef>Provider</th>
      <td mat-cell *matCellDef="let element">{{ element.physician.person.last_name }}, {{
        element.physician.person.first_name | slice : 0 : 1 }}.</td>
    </ng-container>
    <ng-container matColumnDef="appointment_date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.visit_date | date }}</td>
    </ng-container>
    <ng-container matColumnDef="cpt_codes">
      <th mat-header-cell *matHeaderCellDef>CPT</th>
      <td mat-cell *matCellDef="let element">
        <mat-chip-list>
          <ng-container *ngFor="let chip of element.codes; trackBy: trackByChip">
            <mat-chip *ngIf="chip.code_type == 'CPT'" selected [color]="cptColor"
              matTooltip="{{ chip.description }}" (click)="openSnackBar(chip.description)">
              {{ chip.code }}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
    </ng-container>
    <ng-container matColumnDef="icd10_codes">
      <th mat-header-cell *matHeaderCellDef>ICD10</th>
      <td mat-cell *matCellDef="let element">
        <mat-chip-list>
          <ng-container *ngFor="let chip of element.codes">
            <mat-chip *ngIf="chip.code_type == 'ICD-10'"  selected [color]="icdColor" matTooltip="{{ chip.description }}"
              (click)="openSnackBar(chip.description)">
              {{ chip.code }}
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </td>
    </ng-container>
    <ng-container matColumnDef="notes">
      <th mat-header-cell *matHeaderCellDef>Notes</th>
      <td mat-cell *matCellDef="let element"> {{ element['notes'] | slice:0:75 }}...</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="example-element-row"></tr>
  </table>
</div>