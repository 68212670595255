
<mat-placeholder>
    <mat-toolbar color="primary" style="padding-left: 6px; padding-right: 0px;">
        <img style="height: 44px; margin-right: 6px;" src="assets/images/ffpeds-logo.png">
            <span>Families First Pediatrics</span>
    </mat-toolbar>

    <mat-card>
        <mat-card-content>
            <form [formGroup]="form" (ngSubmit)="submit()">
                <mat-form-field appearance="fill">
                    <mat-label>UserName</mat-label>
                    <input type="text" matInput formControlName="username" />
                    <mat-error *ngIf="form.controls['username'].hasError('required')">
                        Please enter a valid username.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input type="password" matInput formControlName="password" />
                    <mat-error *ngIf="form.controls['password'].hasError('required')">
                        Please enter a valid password.
                    </mat-error>
                </mat-form-field>
                <div *ngIf="error" >
                    {{ error }}
                </div>
                
                <button type="submit" [disabled]="getIsSubmitActive()" sizeLarge mat-raised-button color="primary">Login</button>              
            </form>
        </mat-card-content>
    </mat-card>
</mat-placeholder>

