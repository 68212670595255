export class Code {
    code_id: number;
    code_order: number;
    code: string;
    description: string;
    code_type: string;
    is_active?: boolean;

    // sort codes by code id
    public static sortCodesById(a: Code, b: Code): number {
        let comparison: number = 0;
        if (a.code_id > b.code_id) {
            comparison = -1;
        } else if (a.code_id < b.code_id) {
            comparison = 1;
        }
        return comparison;
    }

    // sort codes by code id
    public static sortCodesByCode(a: Code, b: Code): number {
        let comparison: number = 0;
        if (a.code > b.code) {
            comparison = 1;
        } else if (a.code < b.code) {
            comparison = -1;
        }
        return comparison;
    }

    // sort codes by code id
    public static sortCodesByOrder(a: Code, b: Code): number {
        let comparison: number = 0;
        if (a.code_order > b.code_order) {
            comparison = 1;
        } else if (a.code_order < b.code_order) {
            comparison = -1;
        }
        return comparison;
    }

}
