import { Component, OnInit } from '@angular/core';
import { UrlTree, Router, ActivatedRoute } from '@angular/router';
import { takeUntil, tap, map, take, first } from 'rxjs/operators';
import { AppComponent } from '@app/app.component';
import { PageTitleService } from '@app/services/page-title.service';
import { Patient } from '@app/models/patient';
import { Status } from '@app/models/status';
import { Hospital } from '@app/models/hospital';
import { HttpService } from '@app/services/http.service';
import { LoaderService } from '@app/services/loader.service';
import { environment } from '@env/environment';
import { BaseComponent } from '@app/base.component';
import { RouteDataService } from '@app/services/route-data.service';

@Component({
  selector: 'app-patient-select',
  templateUrl: './patient-select.component.html',
  styleUrls: ['./patient-select.component.css']
})
export class PatientSelectComponent extends BaseComponent implements OnInit {

  columnsToDisplay: string[] = [ 'id', 'name', 'username' ];   
  hospital: Hospital = null;
  expandedElement: any | null;
  hasData: boolean = false;
  urlBack: string = null;
  statuses: Status[] = [];

  constructor(
    private pageTitleService: PageTitleService,
    private appComponent: AppComponent,
    private router: Router,
    private routeDataService: RouteDataService,
    private activatedRoute: ActivatedRoute,
    private httpService: HttpService,
    private loaderService: LoaderService
  ) { 
    super();
  }
  
  fetchHospital(): void {
    this.activatedRoute.params
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( params => {

      let urlApi: string = this.httpService.createUrl([ 
        'api', 
        'hospitals', 
        params.hospital_id 
      ], 
        { "queryParams": { "include": [ "patients", "current_status" ] }},
        { host: environment.host_api_default }
      );

      this.httpService.get( urlApi )
        .pipe(
          tap( _ => null )
        ).subscribe(data => {
          this.hospital = data;
        }, (error) => {
          // do nothing
        }, () => {
          this.loaderService.hide();
        });
    });
  }

  // add new patient to hospital
  handlePatientAdd( hospital: Hospital ): void {
    const urlAdd = this.router.createUrlTree([
      'patient',
      'add',
      hospital.hospital_id
    ]);
    this.router.navigateByUrl( urlAdd );
  }

  // handle back command
  handleBack(): void {
    this.router.navigateByUrl( this.urlBack ); // navigate() takes in the same paramaters as url tree (see above)
  }
  
  // what to do when the edit button is clicked
  onRowView( element: Patient ): void {
    // UrlTree is a representation of a URL in object form, see https://angular.io/api/router/UrlTree
    // the Router has methods to handle all URL construction/manipulation, see https://angular.io/api/router/Router
    // the router.navigateByUrl() method takes in a URL string or UrlTree, it seems that UrlTree is the best method to build a URL
    // to generate a UrlTree, use the this.router.createUrlTree() method
    // UrlTree(['patient','edit', element.id, { a: 'b' } ]); // how to add parameters to route
    // UrlTree(['patient','edit', element.id ], { queryParams: {}}); // how to add query parameters
    let urlPatientEdit: UrlTree = this.router.createUrlTree( ['patient','view', element.patient_id ] );
    this.router.navigateByUrl( urlPatientEdit ); // navigate() takes in the same paramaters as url tree (see above)
  }

  ngDoCheck(): void {
    // detect and act upon changes that Angular can't or won't detect on its own.
  }

  ngOnInit(): void {
    this.loaderService.show();

    this.appComponent.appendSiteTitle( 'Patient', 'Select' );
    this.pageTitleService.setPageTitle( 'Select Patient' );

    this.routeDataService.routeData$.pipe(
      takeUntil( this.appUnsubscribe ),
      first(( data ) => data.statuses ),
      take(1),
      map( data => {
        this.statuses = data['statuses'].filter( status => status.status_slug === 'ACTIVE' ||  status.status_slug === 'COMPLETED' ) as Status[];
        return data;
      })
    ).subscribe( result => {
      // do nothing
    });

    // go back to dashboard
    this.urlBack = this.router.createUrlTree([ '/' ]).toString();
    this.fetchHospital();
  }

}
