<mat-toolbar color="accent">
    <mat-toolbar-row>
        <span>
            Select a Field
        </span>
        <span class="spacer"></span>
            <button mat-raised-button type="button" aria-label="Edit" (click)="handleBack()">
            <mat-icon>chevron_left</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<div class="flex-container">
    <form class="flex-item flex-container">
        <mat-form-field appearance="standard" tabindex="0">
            <input #filter matInput type="text" (keyup)="handleFilter( $event )" placeholder="Filter records..." />
        </mat-form-field>
    </form>
</div>

<table mat-table matSortDirection="asc" matSortActive="desc" [dataSource]="fieldData" matSort class="mat-elevation-z8">
  
    <ng-container matColumnDef="edit">
        <th mat-header-cell class="mat-header-text button-action" *matHeaderCellDef>Edit</th>
        <td mat-cell class="mat-cell-button" *matCellDef="let element">
            <button mat-mini-fab color="white" aria-label="Edit field"
                (click)="handleRowEdit( element )">
                <mat-icon>edit</mat-icon>
            </button>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="field_name">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Field</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
            {{ element['field_name'] }}
        </td>
    </ng-container>

    <ng-container matColumnDef="table_name">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Table</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
          {{ element['table_name'] }}
        </td>
    </ng-container>

    <ng-container matColumnDef="table_name_displayed">
        <th mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
          {{ element['table_name_displayed'] }}
        </td>
    </ng-container>

    <ng-container matColumnDef="display_field">
        <th style="width: 80px;" mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Display?</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
          {{ element['display_field'] }}
        </td>
    </ng-container>
  
    <ng-container matColumnDef="require_field">
        <th style="width: 80px;" mat-header-cell class="mat-cell-name" *matHeaderCellDef mat-sort-header>Require?</th>
        <td mat-cell class="mat-cell-name" *matCellDef="let element"> 
          {{ element['require_field'] }}
        </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>
</table>

