import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, UrlCreationOptions, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

// https://betterprogramming.pub/angular-api-calls-the-right-way-264198bf2c64

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { 
  }

  public createUrl( commands: any[], extras: UrlCreationOptions = {}, options: any = {} ): string {
    let url: UrlTree = this.router.createUrlTree([ 
      ...commands
    ],
      extras
    );
    if( options.host )
    {
      return options.host + url.toString();
    } else {
      return url.toString();
    }
  }

  public get<T>( url: string, options?: any ): Observable<any> {
    return this.httpClient.get<T>( url, options );
  }

  public post<T>( url: string, data: any, options?: any ): Observable<any> {
    return this.httpClient.post<T>( url, data, options );
  }

  public put<T>( url: string, data: any, options?: any ): Observable<any> {
    return this.httpClient.put<T>( url, data, options );
  }

  public delete<T>( url: string, options?: any ): Observable<any> {
    return this.httpClient.delete<T>( url, options );
  }
}