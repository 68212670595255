import { ElementRef, Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '@app/base.component';
import { fromEvent, takeUntil, tap, filter, map, from, mergeMap } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import createPanZoom from 'panzoom';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.css']
})
export class ImageZoomComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @ViewChild('chartImage') chartImage: ElementRef;
  @ViewChild('chartImageCard') chartImageCard: ElementRef;
  
  @Input() imageSource: any[] = [];
  @Output() onResetClick: EventEmitter<any> = new EventEmitter();

  // mouse events
  // https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events/Pinch_zoom_gestures
  chartImageElement: any = null;
  chartImageElementZoom: any = null;
  chartImageCardElement: any = null;
  isPointerDown: boolean = false;
  isPointerOver: boolean = false;
  isImageLoaded: boolean = false;
  isViewLoaded: boolean = false;
  pointerCache: Array<PointerEvent> = []; 
  pointerPreviousDifference: number = -1;
  touchCount = 0;
  touchCountPrevious = 0;

  // zoom and rotation options
  imageAngleInDegrees: number = 0;
  imageZoomDelta: number = 0.2;
  imageStartingScale: number = 1;
  imageCurrentScale: number = this.imageStartingScale;
  imageBaseWidth: number;
  imageBaseHeight: number;
  imageBaseBoundingRectangle: DOMRect;
  imageCurrentBoundingRectangle: DOMRect;
  imageCurrentWidth: number;
  imageCurrentHeight: number;
  imageCurrentAngle: number = 0;
  imageCurrentTranslateY: number = 0;
  imageCurrentTranslateX: number = 0;
  imageLastTranslateY: number = 0;
  imageLastTranslateX: number = 0;
  imageStartX: boolean = false;
  imageStartY: boolean = false;
  imageMouseDownX: number = 0;
  imageMouseDownY: number = 0;
  imageTouchDownX: number = 0;
  imageTouchDownY: number = 0;
  imageLastOriginX: number = 0;
  imageLastOriginY: number = 0;

  constructor(
    private deviceDetectorService: DeviceDetectorService
  ) { 
    super();
  }

  ngOnInit(): void {
  }
  
  // https://flaviocopes.com/mouse-events/
  // https://stackoverflow.com/questions/50162290/mouseup-event-not-firing-when-dragging-an-image-in-chrome
  // https://developer.mozilla.org/en-US/docs/Web/API/Pointer_events/Pinch_zoom_gestures
  ngAfterViewInit(): void {

    this.chartImageElement = this.chartImage.nativeElement;
    this.chartImageCardElement = this.chartImageCard.nativeElement;

    this.isViewLoaded = true;

    const onImageLoad$ = fromEvent( this.chartImageElement, 'load' );
    onImageLoad$.pipe(
      takeUntil( this.appUnsubscribe ),
      map( e => e as Event )
    ).subscribe( e => {
      this.imageBaseWidth = this.chartImageElement.width;
      this.imageBaseHeight = this.chartImageElement.height;
      this.imageCurrentWidth = this.chartImageElement.width;
      this.imageCurrentHeight = this.chartImageElement.height;
      this.imageCurrentBoundingRectangle = this.chartImageElement.getBoundingClientRect();
      this.imageBaseBoundingRectangle = this.imageCurrentBoundingRectangle;

      this.chartImageElementZoom = createPanZoom( this.chartImageElement, {
        minZoom: 1,
        maxZoom: 8,
        // bounds: {
        //   left: 0,
        //   top: 0,
        //   bottom: this.imageBaseHeight,
        //   right: this.imageBaseWidth,
        // },
        bounds: true,
        boundsPadding: 0.75,
        // autocenter: true
      });

    });

    /*
    const onPointerDown$ = fromEvent( this.chartImageElement, 'mousedown' );
    if( !this.deviceDetectorService.isMobile()){
      onPointerDown$.pipe(
        takeUntil( this.appUnsubscribe ),
        map( e => e as PointerEvent )
      ).subscribe( e => {
        e.preventDefault();
        this.handlePointerDown( e );
      });
    }
      
    const cancelEvents = ['mouseup','mouseout'];
    if( !this.deviceDetectorService.isMobile()){
      from( cancelEvents ).pipe(
        takeUntil( this.appUnsubscribe ),
        mergeMap( e => fromEvent( this.chartImageElement, e )),
        map( e => e as PointerEvent )
      ).subscribe( e => {
        if( this.isPointerDown ){
          this.handlePointerUp( e );
        }
      });
    }

    const chartPointerMove$ = fromEvent( this.chartImageElement, 'pointermove' );
    if( !this.deviceDetectorService.isMobile()){
      chartPointerMove$.pipe(
        takeUntil( this.appUnsubscribe ),
        map( e => e as PointerEvent )
      ).subscribe( e => {
        this.handlePointerMove( e );
      });
    }

    const onTouchStart$ = fromEvent( this.chartImageElement, 'touchstart' );
    onTouchStart$.pipe(
      takeUntil( this.appUnsubscribe ),
      map( e => e as TouchEvent )
    ).subscribe( e => {
      this.handleTouchStart( e );
    });

    const onTouchEnd$ = fromEvent( this.chartImageElement, 'touchend' );
    onTouchEnd$.pipe(
      takeUntil( this.appUnsubscribe ),
      map( e => e as TouchEvent )
    ).subscribe( e => {
      this.handleTouchEnd( e );
    });

    const onTouchMove$ = fromEvent( this.chartImageElement, 'touchmove' );
    onTouchMove$.pipe(
      takeUntil( this.appUnsubscribe ),
      map( e => e as TouchEvent )
    ).subscribe( e => {
      this.handleTouchMove( e );
    });

    const chartWheel$ = fromEvent( this.chartImageElement, 'wheel');
    chartWheel$.pipe(
      takeUntil( this.appUnsubscribe ),
      map( e => e as WheelEvent )
    ).subscribe( e => {
        e.preventDefault(); // keep browser from scrolling
        this.handleMouseWheel( e );
    });
      */
  }

  // for all changes
  ngOnChanges( simpleChanges: SimpleChanges ): void {
    // display image if one exists
    if( simpleChanges.imageSource.previousValue !== simpleChanges.imageSource.currentValue && this.isViewLoaded )
    {
      this.chartImageElement;
      this.isImageLoaded = true;
    }
  }
  /*

  // reset image to original size, location, etc.
  handleImageReset(): void {
    this.imageCurrentAngle = 0;
    this.imageCurrentScale = 0;
    this.imageAngleInDegrees = 0;
  }

  // what to do when mouse wheel moves
  handleMouseWheel( e: WheelEvent ): void {

    let wheelEvent = e as WheelEvent;
    this.imageLastOriginX = e.offsetX;
    this.imageLastOriginY = e.offsetY;
    
    // if the deltaY is negative, wheel was turned toward user (down)
    if( wheelEvent.deltaY > 0 )
    {      
      // user is zooming out
      if(( this.imageCurrentScale - this.imageZoomDelta ) >= 1 )
      {
        this.imageCurrentScale -= this.imageZoomDelta;
      }
    } else {
      // user is zooming in 
      this.imageCurrentScale += this.imageZoomDelta;
      this.imageOverflowXPositive = (( this.imageBaseWidth - this.imageLastOriginX ) * this.imageCurrentScale ) - ( this.imageBaseWidth - this.imageLastOriginX );
      this.imageOverflowXNegative = (this.imageLastOriginX * this.imageCurrentScale) - this.imageLastOriginX;
      this.imageOverflowYPositive = (( this.chartImageElement.height - this.imageLastOriginY ) * this.imageCurrentScale ) -  ( this.chartImageElement.height - this.imageLastOriginY );
      this.imageOverflowYNegative = (this.imageLastOriginY * this.imageCurrentScale) - this.imageLastOriginY;
      this.chartImageElement.style.transformOrigin = `${this.imageLastOriginX}px ${this.imageLastOriginY}px`;
    }
    this.imageCurrentWidth = this.imageBaseWidth * this.imageCurrentScale;
    this.imageCurrentHeight = this.imageBaseHeight * this.imageCurrentScale;
    this.imageCurrentBoundingRectangle = this.chartImageElement.getBoundingClientRect();

    this.drawImage();
  }

  // what happens when mouse button is held down
  handlePointerDown( e: PointerEvent ): void {
    this.isPointerDown = true;
    this.imageMouseDownX = e.clientX;
    this.imageMouseDownY = e.clientY;
  }

  // what happens when mouse button is held down
  handleTouchStart( e: TouchEvent ): void {
    this.touchCountPrevious = e.touches.length;
    if( e.touches.length === 1 )
    {
      this.imageTouchDownX = e.touches[0].clientX;
      this.imageTouchDownY = e.touches[0].clientY;
    }
  }

  // what happens when mouse button is held down
  handleTouchEnd( e: TouchEvent ): void {
    // check the current length of touches
    if( this.touchCount === 1 && this.touchCountPrevious === 2 ){
      // went from two fingers to one
    } 
    this.imageLastTranslateX = this.imageCurrentTranslateX * this.imageCurrentScale;
    this.imageLastTranslateY = this.imageCurrentTranslateY * this.imageCurrentScale;  
  }

  // what happens when mouse button is held down; dragging cancels pointerdown event
  handlePointerUp( e: PointerEvent ): void {
    this.isPointerDown = false;
    this.imageLastTranslateX = this.imageCurrentTranslateX;
    this.imageLastTranslateY = this.imageCurrentTranslateY;
  }

  // what happens when image is dragged
  handleTouchMove( e: TouchEvent ):void
  {
    // keep browser from scrolling
    e.preventDefault();

    if( e.touches.length === 1 )
    {
      this.imageCurrentTranslateX = ( this.imageLastTranslateX + ( e.touches[0].clientX - this.imageTouchDownX )) / this.imageCurrentScale;
      this.imageCurrentTranslateY = ( this.imageLastTranslateY + ( e.touches[0].clientY - this.imageTouchDownY )) / this.imageCurrentScale;
      this.drawImage();
    }
    
    // if two pointers are down, check for pinch gestures
    if( e.touches.length === 2 ) {
      const currentDifference = Math.abs( e.touches[0].clientX - e.touches[1].clientX );
      if( this.pointerPreviousDifference > 0 ) {
        if( currentDifference > this.pointerPreviousDifference ) {
          this.imageCurrentScale += this.imageZoomDelta;
        }
        if( currentDifference < this.pointerPreviousDifference ) {
          if(( this.imageCurrentScale - this.imageZoomDelta ) >= 1 )
          {
            this.imageCurrentScale -= this.imageZoomDelta;
          }
        }
      }
      this.pointerPreviousDifference = currentDifference;
      this.drawImage();
    }

  }

  // what happens when image is dragged
  handlePointerMove( e: PointerEvent ):void
  {
    // determine how much the image can move on the x/y axis
    let imageHalfHeight: number = Math.round( this.chartImageElement.height/2 );
    let imageHalfWidth: number = Math.round( this.chartImageElement.width/2 );

    let translateX: number = this.imageLastTranslateX + Math.round(( e.clientX - this.imageMouseDownX ));
    let translateY: number = this.imageLastTranslateY + Math.round(( e.clientY - this.imageMouseDownY ));
    
    if( this.isPointerDown )
    {
      // get bounding rectangle of image
      this.imageCurrentBoundingRectangle = this.chartImageElement.getBoundingClientRect();
      
      // reset the origin to the center for this operation
      if( this.imageCurrentBoundingRectangle.right >= this.imageBaseBoundingRectangle.right && 
        this.imageCurrentBoundingRectangle.left <= this.imageBaseBoundingRectangle.left
      ){
        this.imageCurrentTranslateX = translateX;
      }

      if( this.imageCurrentBoundingRectangle.top >= this.imageBaseBoundingRectangle.top &&
        this.imageCurrentBoundingRectangle.bottom <= this.imageBaseBoundingRectangle.bottom
      ){
        this.imageCurrentTranslateY = translateY;
      }

      this.drawImage();
    }
  }
  
  private drawImage(): void {
    this.chartImageElement.style.transform = `scale(${this.imageCurrentScale}) 
    translateY(${this.imageCurrentTranslateY}px) 
    translateX(${this.imageCurrentTranslateX}px)`;
  }
  */

}
