import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpService } from '@app/services/http.service';
import { environment } from '@env/environment';
import { Field } from '@app/models/field';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AppComponent } from '@app/app.component';
import { PageTitleService } from '@app/services/page-title.service';
import { LoaderService } from '@app/services/loader.service';
import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-field-edit',
  templateUrl: './field-edit.component.html',
  styleUrls: ['./field-edit.component.css']
})
export class FieldEditComponent extends BaseComponent implements OnInit {

  // variables
  private isLoaded: boolean = false;
  subTitle: string = "Edit Field";
  urlBack: string = null;
  fieldId: number = null;
  field: Field;

  // field form
  fieldForm = new UntypedFormGroup({
    require_field: new UntypedFormControl( null, {}),
    display_field: new UntypedFormControl( null, {}),
  });

  constructor(
    private httpService: HttpService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appComponent: AppComponent,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService
  ) {
    super();
    this.urlBack = this.router.createUrlTree(['/administration/fields']).toString();

    this.loaderService.show();
    this.activatedRoute.params.pipe(
      takeUntil( this.appUnsubscribe )
    ).subscribe(params => {
      if (params.application_field_id) {
        this.fieldId = params.application_field_id;

        this.fetchField( this.fieldId ).pipe(
          tap( _ => null )
        ).subscribe(data => {
          this.field = data;
          this.fieldForm.patchValue({ require_field: data.require_field });
          this.fieldForm.patchValue({ display_field: data.display_field });
        }, () => {
          // do nothing
        }, () => {
          this.isLoaded = true;
          this.loaderService.hide();
        });
      }
    });
  }

  handleBack(): void {
    this.router.navigateByUrl(this.urlBack);
  }

  isFormLoaded(): boolean {
    return this.isLoaded;
  }

  // handle whether or not submit button should be active
  getIsSubmitActive(): boolean {
    return !this.fieldForm.valid;
  }

  // handle form submit
  handleSubmit(): void {
    if (this.fieldForm.valid) {
      this.loaderService.show();
      this.updateField();
    }
  }

  updateField(): void {

    const urlApi = this.httpService.createUrl([
      'api',
      'fields',
      this.fieldId
    ], {}, { host: environment.host_api_default});

    let update = this.httpService.put(urlApi, this.fieldForm.value);
    update.subscribe( field  => {
      this.loaderService.hide();

      const urlFields = this.router.createUrlTree([
        'administration',
        'fields'
      ]);

      this.router.navigateByUrl( urlFields );
    });
  }

  fetchField( application_field_id: number): Observable<Field> {
    let urlApi: string = this.httpService.createUrl([
      'api',
      'fields',
      application_field_id
    ], {}, { host: environment.host_api_default });

    return this.httpService.get<Field>(urlApi);
  }

  ngOnInit(): void {
    this.pageTitleService.setPageTitle('Field Administration');
    this.appComponent.appendSiteTitle('administration', 'users', 'edit')
  }
}
