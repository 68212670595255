import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { User } from '@app/models/user';
import { LoaderService } from '@app/services/loader.service';
import { PageTitleService } from '@app/services/page-title.service';
import { AppComponent } from '@app/app.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeDialogComponent } from '@app/modules/admin/password-change-dialog/password-change-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from '@app/base.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent extends BaseComponent implements OnInit {

  user: User = null;

  constructor(
    private authService: AuthService,
    private appComponent: AppComponent,
    private loaderService: LoaderService,
    private pageTitleService: PageTitleService,
    private passwordDialog: MatDialog,
    private snackBarService: MatSnackBar
  ){ 
    super();
    this.loaderService.show();
    this.authService.currentUser$
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      this.user = result;
      this.loaderService.hide();
    });
  }

  ngOnInit(): void {
    this.appComponent.appendSiteTitle( 'User', 'Account' );
    this.pageTitleService.setPageTitle( 'User Account' );
  }

  changePassword(): void {
    const passDialog = this.passwordDialog.open(PasswordChangeDialogComponent, {
      width: '300px',
      data: { user_id: this.user.user_id, required: false, is_current_user: true }
    });

    passDialog.afterClosed()
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe(result => {
      if (result != null) {
        this.snackBarService.open(`Password change ${result}`, `Ok`, {
          duration: 3000
        });
      }
    });
  }

  // user clicked logout button
  handleButtonLogout(): void {
    this.authService.logout();
  }

}
