import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule} from '@angular/material/slider';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { DefaultModule } from './layouts/default/default.module';
import { SharedModule } from './shared/shared.module';
import { AuthorizationModule } from './modules/authorization/authorization.module';
import { PatientModule } from './modules/patient/patient.module'
import { Config } from './config/config';
import { AuthInterceptor } from './services/auth.service';
import { AdminModule } from './modules/admin/admin.module';
import { BaseComponent } from './base.component';
// https://www.primefaces.org/primeng/ //to import more ui components
// https://angular.io/api/common/NgForOf //use trackBy functions to speed up angular
// https://www.freecodecamp.org/news/best-practices-for-a-clean-and-performant-angular-application-288e7b39eb6f/ //angular best practices
// https://angular.io/guide/universal //explains how to compile pages server side and why
// https://medium.com/@richardr39/using-angular-cli-to-serve-over-https-locally-70dab07417c8 // run angular over local https

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatGridListModule,
    MatSidenavModule,
    MatToolbarModule,
    DefaultModule,
    SharedModule,
    AuthorizationModule,
    PatientModule,
    AdminModule
  ],
  providers: [ // adding providers here makes them available as singletons to the entire application
    Title,
    Config,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
