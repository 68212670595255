<div class="flex-container">
    <form [formGroup]="visitForm" class="flex-item flex-container">

        <mat-form-field appearance="fill">
            <mat-label>Provider</mat-label>
            <mat-select name="physician_id" formControlName="physician_id" required>
                <mat-option *ngFor="let provider of providers" [value]="provider.person_id">{{provider.last_name}}, {{provider.first_name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Visit Date</mat-label>
            <input name="visit_date" matInput [matDatepicker]="picker_visit_date" formControlName="visit_date" required />
            <mat-datepicker-toggle matSuffix [for]="picker_visit_date"></mat-datepicker-toggle>
            <mat-datepicker #picker_visit_date></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Previous Visit CPT Codes</mat-label>
            <mat-chip-list
                #previous_cpt_codes_chip_list
                cdkDropListOrientation="horizontal">
                <mat-chip *ngFor="let code of previousVisitCptCodes" matTooltip="{{ code.description }}" (click)="openSnackBar(code.description)">
                    {{code.code}}
                </mat-chip>
            </mat-chip-list>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Previous Visit ICD-10 Codes</mat-label>
            <mat-chip-list
                #previous_cpt_codes_chip_list
                cdkDropListOrientation="horizontal">
                <mat-chip *ngFor="let code of previousVisitIcdCodes" matTooltip="{{ code.description }}" (click)="openSnackBar(code.description)">
                    {{code.code}}
                </mat-chip>
            </mat-chip-list>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>CPT Codes</mat-label>
            <mat-chip-list #cpt_codes_chip_list formControlName="cpt_codes">
                <mat-chip 
                    *ngFor="let code of cptCodes" 
                    [selectable]="true"
                    matTooltip="{{ code.description }}"
                    (click)="openSnackBar(code.description)"
                    [removable]="true" 
                    (removed)="handleRemoveCptCode( code )">
                {{code.code}} <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input 
                    placeholder="Select CPT Code..."
                    #cpt_codes
                    [formControl]="inputCpt"
                    [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                    [matChipInputFor]="cpt_codes_chip_list" 
                    [matAutocomplete]="autoCpt" 
                    [matChipInputAddOnBlur]="true" />
            </mat-chip-list>
            <mat-autocomplete #autoCpt="matAutocomplete" (optionSelected)="cptSelected( $event )">
                <mat-option *ngFor="let code of filteredCptCodes | async" [value]="code.code_id">
                    {{code.code}}: {{code.description}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        
        <mat-form-field appearance="fill">
            <mat-label>ICD-10 Codes</mat-label>
            <mat-chip-list #icd_codes_chip_list formControlName="icd_codes">
                <mat-chip 
                    *ngFor="let code of icdCodes" 
                    [selectable]="true"
                    [removable]="true"
                    matTooltip="{{ code.description }}"
                    (click)="openSnackBar(code.description)"
                    (removed)="handleRemoveIcdCode( code )">
                    {{code.code}} <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>

                <input 
                    placeholder="Select ICD-10 Code..."
                    #icd_codes
                    [formControl]="inputIcd"
                    [matChipInputFor]="icd_codes_chip_list" 
                    [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
                    [matAutocomplete]="autoIcd" 
                    [matChipInputAddOnBlur]="true" />                
            </mat-chip-list>
            <mat-autocomplete #autoIcd="matAutocomplete" (optionSelected)="icdSelected( $event )">
                <mat-option *ngFor="let code of filteredIcdCodes | async" [value]="code.code_id">
                    {{code.code}}: {{code.description}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Existing Visit Notes (Aggregated)</mat-label>
            <textarea disabled="true" [cdkTextareaAutosize]="true" matInput >{{ allVisitNotes }}</textarea>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Notes</mat-label>
            <textarea #notes_control formControlName="notes" [cdkTextareaAutosize]="true" name="notes" matInput maxlength="250"></textarea>
            <mat-hint align="end">{{notes_control.value.length}}/256</mat-hint>
        </mat-form-field>

        <div class="controls">
            <button name="button-submit" (click)="handleSubmit('default')" [disabled]="getIsSubmitActive()" mat-raised-button type="submit" color="primary">Submit</button>
            <button *ngIf="canComplete" (click)="handleSubmit('complete')" [disabled]="getIsSubmitActive()" mat-raised-button type="submit" color="secondary">Submit & Complete</button>
            <button *ngIf="canDelete" name="button-delete" mat-raised-button type="submit" color="error">Delete</button>
        </div>

    </form>
</div>