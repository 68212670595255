import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

export enum DOC_ORIENTATION {
  Up = 1,
  Down = 3,
  Right = 8,
  Left = 6,
  UpMirrored = 2,
  DownMirrored = 4,
  LeftMirrored = 5,
  RightMirrored = 7,
  NotJpeg = -1,
  NotDefined = -2
}

@Injectable({
  providedIn: 'root'
})
export class ImageCompressor {

  private render: Renderer2;
  public DOC_ORIENTATION = DOC_ORIENTATION;

  constructor( rendererFactory: RendererFactory2 ) {
    this.render = rendererFactory.createRenderer(null, null);
  }

  public byteCount(image) {
    return ImageCompressor.handleByteCount(image);
  }

  /** Get the correct Orientation value from the EXIF tags in the specified file. */
  static getOrientation(file: File): Promise<DOC_ORIENTATION> {
    return new Promise<DOC_ORIENTATION>((resolve) => {
      ImageCompressor.handleOrientation(file, (result) => {
        resolve(result);
      });
    });
  }

  public uploadFile( event: any ): Promise<{ image: string, orientation: DOC_ORIENTATION }> {
    return this.handleUploadFile( event );
  }

  public compressFile(image: string, orientation: DOC_ORIENTATION, ratio: number = 50, quality: number = 50): Promise<any> {
    return this.handleCompress(image, orientation, this.render, ratio, quality);
  }

  /**
   * Get the correct Orientation value from tags, in order to write correctly in our canvas
  */
  static handleOrientation(file: File, callback: (result: DOC_ORIENTATION) => void ) {
    const reader = new FileReader();
    try {
      reader.onload = function ( $event ) {
        const view = new DataView(reader.result as ArrayBuffer);
        if (view.getUint16(0, false) !== 0xFFD8) {
          return callback(-2);
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          const marker = view.getUint16(offset, false);
          offset += 2;
          if (marker === 0xFFE1) {
            if (view.getUint32(offset += 2, false) !== 0x45786966) {
              return callback(-1);
            }
            const little = view.getUint16(offset += 6, false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                return callback(view.getUint16(offset + (i * 12) + 8, little));
              }
            }
          } else if ((marker & 0xFF00) !== 0xFF00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        return callback(-1);
      };
      reader.readAsArrayBuffer(file);
    } catch (e) {
      return callback(0);
    }
  }

  /**
   * return a promise with the new image data and image orientation
   */
  private handleUploadFile( event: any ): Promise<{ image: string, orientation: DOC_ORIENTATION }> {

    const promise: Promise<{ image: string, orientation: DOC_ORIENTATION }> = new Promise(function (resolve, reject) {

      // get files from file input
      let files: FileList = event.target.files;
      let file = files.item(0);

      const myReader: FileReader = new FileReader();
      myReader.onloadend = (e) => {
        try {
          ImageCompressor.handleOrientation(file, orientation => {
            resolve({image: myReader.result as string, orientation});
          });
        } catch (e) {
          // console.log(`ngx-image-compress error ${e}`);
          reject(e);
        }
      };

      try {
        myReader.readAsDataURL(file);
      } catch (e) {
        console.warn(`Probably no file have been selected: ${e}`);
        reject('No file selected');
      }
    });

    return promise;
  }

  /**
   * Get image data.
   * 
   * @param imageDataUrlSource 
   */
  public imageData( imageDataUrlSource: string ): Promise<any>
  {
    const promise: Promise<any> = new Promise((resolve, reject) =>{
      const sourceImage = new Image();
      sourceImage.onload = function () {
        resolve( sourceImage );
      };
      sourceImage.src = imageDataUrlSource;
    });

    return promise;
  }

  /**
   * Compress image.
   * 
   * @param imageDataUrlSource 
   * @param orientation 
   * @param render 
   * @param ratio 
   * @param quality 
   * @returns 
   */
  private handleCompress( imageDataUrlSource: string, orientation: DOC_ORIENTATION, render: Renderer2, ratio: number = 50, quality: number = 50): Promise<any> {

    const promise: Promise<any> = new Promise(function (resolve, reject) {

      quality = quality / 100;
      ratio = ratio / 100;
      const sourceImage = new Image();

      // important for safari: we need to wait for onload event
      sourceImage.onload = function () {

        const canvas: HTMLCanvasElement = render.createElement('canvas');
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
        const TO_RADIANS = Math.PI / 180;

        let w, h;
        w = sourceImage.naturalWidth;
        h = sourceImage.naturalHeight;

        // if (orientation === DOC_ORIENTATION.Right || orientation === DOC_ORIENTATION.Left) {
        //   const t = w;
        //   w = h;
        //   h = t;
        // }

        canvas.width = w * ratio;
        canvas.height = h * ratio;

        // if (orientation === DOC_ORIENTATION.Up) {

        ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);

        // } else if (orientation === DOC_ORIENTATION.Right) {

        //   ctx.save();
        //   ctx.rotate(90 * TO_RADIANS);
        //   ctx.translate(0, -canvas.width);
        //   ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
        //   ctx.restore();

        // } else if (orientation === DOC_ORIENTATION.Left) {

        //   ctx.save();
        //   ctx.rotate(-90 * TO_RADIANS);
        //   ctx.translate(-canvas.width, 0);
        //   ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
        //   ctx.restore();

        // } else if (orientation === DOC_ORIENTATION.Down) {

        //   ctx.save();
        //   ctx.rotate(180 * TO_RADIANS);
        //   ctx.translate(-canvas.width, -canvas.height);
        //   ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        //   ctx.restore();

        // } else {
        //   // console.warn('ngx-image-compress - no orientation value found');
        //   // same as default UP
        //   ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        // }

        let mimeString = imageDataUrlSource.substr(5, imageDataUrlSource.split(';')[0].length - 5);
        let imageCompressed = canvas.toDataURL(mimeString, quality);

        let byteString = atob( imageCompressed.split(',')[1]);
        let imageBuffer = new ArrayBuffer( byteString.length );
        let imageArray = new Uint8Array( imageBuffer );
        for( let i=0; i<byteString.length; i++)
        {
          imageArray[i] = byteString.charCodeAt(i);
        }
        let imageBlob = new Blob([imageBuffer], {'type': mimeString});

        const result = {
          'image': imageCompressed,
          'mime': mimeString,
          'blob': imageBlob
        }
        resolve(result);
      };

      sourceImage.src = imageDataUrlSource;
    });

    return promise;
  }

   /**
   * helper to evaluate the compression rate
   * @param s the image in base64 string format
   */
  static handleByteCount(s: string): number {
    return encodeURI(s).split(/%..|./).length - 1;
  } 

}

