import { Physician } from "./physician";
import { User } from "./user";

export class Person {
    person_id: number;
    first_name: string;
    last_name: string;
    preferred_name?: string;
    phone?: string;
    user: User;
    physician: Physician
}
