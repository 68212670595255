import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '@app/services/page-title.service';
import { AppComponent } from '@app/app.component';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { map, tap, first, take, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, merge, concat } from 'rxjs';
import { HttpService } from '@app/services/http.service';
import { Patient } from '@app/models/patient';
import { LoaderService } from '@app/services/loader.service';
import { Visit } from '@app/models/visit';
import { Code } from '@app/models/code';
import { Physician } from '@app/models/physician';
import { Status } from '@app/models/status';
import { environment } from '@env/environment';
import { RouteDataService } from '@app/services/route-data.service';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseComponent } from '@app/base.component';
import { HasChartValidator } from '@app/library/validators.class';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-patient-visit-edit',
  templateUrl: './patient-visit-edit.component.html',
  styleUrls: ['./patient-visit-edit.component.css']
})
export class PatientVisitEditComponent extends BaseComponent implements OnInit {

  urlBack: UrlTree = null;
  urlBackDelete: UrlTree = null;
  visit: Visit = null;
  visits: Visit[] = [];
  physicians: Physician[] = [];
  visitId: number = null;
  previousVisitCptCodes: Code[] = [];
  previousVisitIcdCodes: Code[] = [];
  allIcdCodes: Code[] = [];
  allCptCodes: Code[] = [];
  statuses: Status[] = [];
  themeWarn: ThemePalette = "warn";

  constructor(
    public dialogDelete: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appComponent: AppComponent,
    private httpService: HttpService,
    private pageTitleService: PageTitleService,
    private loaderService: LoaderService,
    private routeDataService: RouteDataService,
    private snackBarService: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.appComponent.appendSiteTitle( 'Patient', 'Visit', 'Edit' );
    this.pageTitleService.setPageTitle( 'Edit Visit' );
    this.loaderService.show();

    let promiseStatuses = this.routeDataService.routeData$.pipe(
    first(( data ) =>  data['statuses'] ),
      take(1),
      map( data => {
        this.statuses = data['statuses'];
      })
    );

    let promiseCodes = this.routeDataService.routeData$.pipe(
    first(( data ) =>  data['codes'] ),
      take(1),
      map( data => {
        let codes = data['codes'];
        this.allCptCodes = codes.filter(( code: Code ) => ( code.code_type === 'CPT' ? true : false ));
        this.allIcdCodes = codes.filter(( code: Code ) => ( code.code_type === 'ICD-10' ? true : false ));
        return codes;
      })
    );

    let promisePhysician = this.routeDataService.routeData$.pipe(
    first(( data ) =>  data['physicians'] ),
      take(1),
      map( data => {
        this.physicians = data['physicians'];
      })
    );

    const promiseVisit = this.activatedRoute.params.pipe(
      first( params => params.visit_id ),
      take(1),
      map( params => {
        this.visitId = params.visit_id;
        return params;
      }),
      switchMap( () => this.fetchVisit() ),
      switchMap( () => this.fetchVisits() )
    );

    // run set of promises asynchronously 
    let promises = merge( promiseVisit, promiseCodes, promiseStatuses, promisePhysician );
    promises
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe({
      'next': ( r ) => {
        this.urlBack = this.router.createUrlTree( ['patient','visit', this.visitId ] );
      },
      'error': ( e ) => {
        console.error( e );
      },
      'complete': () => {
        this.loaderService.hide();
      }
    });
  }

  // when the back button is clicked
  handleBack(): void {
    this.router.navigateByUrl( this.urlBack ); // navigate() takes in the same paramaters as url tree (see above)
  }

  // when delete button is clicked
  handleButtonDelete(): void {
    const dialogReference = this.dialogDelete.open( PatientVisitEditDialogDeleteComponent );
    dialogReference.afterClosed()
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe({
      'next': ( r ) => {
        if( r == true )
        {
          this.handleDelete();
        }
      }
    });
  }

  // delete current visit
  private handleDelete(): void {

    this.loaderService.show();
    let urlApiVisit: string = this.httpService.createUrl([ 
      'api', 
      'visits',
      this.visitId
    ],
      {},
      { host: environment.host_api_default }
    );

    this.httpService.delete( urlApiVisit )
    .pipe(
      tap( _ => { }),
    ).subscribe({
      'next': ( r ) => {
        this.router.navigateByUrl( this.urlBackDelete );
      },
      'error': ( e ) => {
        console.error( e );
      },
      'complete': () => {
        this.loaderService.hide();
      }
    });
  }

  // get current visit
  fetchVisit(): Observable<any> {

    // pateint api endpoint
    let urlApiPatient: string = this.httpService.createUrl([ 
      'api', 
      'visits',
      this.visitId
    ],
      { 'queryParams': { include: 'codes' } },
      { host: environment.host_api_default }
    );

    let promiseVisit = this.httpService.get<Patient>( urlApiPatient )
      .pipe(
        tap( _ => console.log( '...loading visit form data' )),
        map(( result: Visit ) => result as Visit ),
        map(( result ) => {
          this.visit = result;
          this.urlBackDelete = this.router.createUrlTree( ['patient','view', this.visit.patient_id ] );
        })
      );

    return promiseVisit;
  }

  // get current visits
  fetchVisits(): Observable<any> {

    // patient api endpoint
    let urlApiVisit: string = this.httpService.createUrl([ 
      'api', 
      'visits',
      'patient',
      this.visit.patient_id
    ],
      { 'queryParams': { include: [ 'codes' ] } },
      { host: environment.host_api_default }
    );

    let promiseVisits = this.httpService.get<Visit[]>( urlApiVisit )
      .pipe(
        tap( _ => console.log( '...loading additional visit data' )),
        map(( result: Visit[] ) => result as Visit[] ),
        map(( result ) => { 
          this.visits = Visit.mapToVisits( result );
          this.previousVisitCptCodes = Visit.extractVisitCodesByType( this.visits, 'CPT' );
          this.previousVisitIcdCodes = Visit.extractVisitCodesByType( this.visits, 'ICD-10' );
        }),
      );

    return promiseVisits;
  }

  hasChart(): Observable<boolean> {
    return HasChartValidator(this.httpService, this.visit.patient_id)
    .pipe(
      takeUntil(this.appUnsubscribe),
      map(result => {
        if (!result) {
          this.snackBarService.open(`Unable complete patient: Chart is required.`, `Dismiss`, {
            duration: 5000
          });
          throw new Error("No chart exists!");
        }
        return result;
      }));
  }

  /**
   * What to do if the "Save & Complete" button has been clicked.
   * 
   * @returns Observable
   */
  handlePatientComplete(): Observable<Status> {
    let status = Status.filterBySlug( this.statuses, 'COMPLETED' );

    let put = {
      'status_id': status.status_id
    };

    let urlApiPatient: string = this.httpService.createUrl([
      'api',
      'patients',
      this.visit.patient_id
    ],
      { },
      { host: environment.host_api_default }
    );
    return this.httpService.put<Status>( urlApiPatient, put );
  }

  // update patient details via api
  handleSubmit( event: any ): void {

    let visit = event.visit;
    let observer: Observable<any>;

    // add new visit to the database
    const urlApi = this.httpService.createUrl([
      'api',
      'visits',
      this.visitId
    ], {}, {
      host: environment.host_api_default
    });

    if( event.complete == true ){
      observer = concat(
        this.hasChart(),
        this.handlePatientComplete(),
        this.httpService.put( urlApi, visit )
      );
    } else {
      observer = this.httpService.put( urlApi, visit );
    }
    
    // send visit to the database
    this.loaderService.show();
    observer.subscribe({
      'next': ( r ) => {
        console.error( r, event );
      },
      'error': ( e ) => {
        this.loaderService.hide();
      },
      'complete': () => {
        this.loaderService.hide();
        if ( event.complete ) {
          this.router.navigateByUrl( this.router.createUrlTree(['patient', 'list']) );
        } else {
          this.router.navigateByUrl( this.urlBack );
        }
      }
    });      
  }
}

// dialog specific to this component
@Component({
  selector: 'app-patient-visit-edit-dialog-delete',
  templateUrl: 'patient-visit-edit-dialog-delete.html'
})
export class PatientVisitEditDialogDeleteComponent {
}