import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDatepickerModule }  from '@angular/material/datepicker';
import { SharedModule } from '@app/shared/shared.module';
import { PatientAddComponent } from './patient-add/patient-add.component';
import { PatientEditComponent, PatientEditDialogDeleteComponent } from './patient-edit/patient-edit.component';
import { PatientSelectComponent } from './patient-select/patient-select.component';
import { PatientViewComponent } from './patient-view/patient-view.component';
import { PatientVisitAddComponent } from './patient-visit-add/patient-visit-add.component';
import { PatientVisitEditComponent, PatientVisitEditDialogDeleteComponent } from './patient-visit-edit/patient-visit-edit.component';
import { PatientVisitViewComponent } from './patient-visit-view/patient-visit-view.component';
import { PatientListAllComponent } from './patient-list-all/patient-list-all.component';
import { PatientAddChartComponent } from './patient-add-chart/patient-add-chart.component';

const routes: Routes = [
  {
    data: {
      title: 'Patient List'
    },
    path: 'list',
    component: PatientListAllComponent
  },
  {
    data: {
      title: 'Edit'
    },
    path: 'edit/:patient_id',
    component: PatientEditComponent
  },
  {
    data: {
      title: 'Add Visit'
    },
    path: 'visit/add/:patient_id',
    component: PatientVisitAddComponent
  },
  {
    data: {
      title: 'Edit Visit'
    },
    path: 'visit/edit/:visit_id',
    component: PatientVisitEditComponent
  },
  {
    data: {
      title: 'View Visit'
    },
    path: 'visit/:visit_id',
    component: PatientVisitViewComponent
  },
  {
    data: {
      title: 'Add'
    },
    path: 'add',
    component: PatientAddComponent
  },
  {
    data: {
      title: 'Add'
    },
    path: 'add/:hospital_id',
    component: PatientAddComponent
  },
  {
    data: {
      title: 'View'
    },
    path: 'view/:patient_id',
    component: PatientViewComponent
  },
  {
    data: {
      title: 'Chart'
    },
    path: 'view/:patient_id/chart',
    component: PatientAddChartComponent
  },
  {
    data: {
      title: 'Select'
    },
    path: 'select/:hospital_id',
    component: PatientSelectComponent
  }
];

@NgModule({
  declarations: [
    PatientAddComponent, 
    PatientEditComponent,
    PatientSelectComponent, 
    PatientViewComponent, 
    PatientVisitAddComponent, 
    PatientVisitEditComponent,
    PatientEditDialogDeleteComponent, 
    PatientVisitEditDialogDeleteComponent, 
    PatientVisitViewComponent, 
    PatientListAllComponent, PatientAddChartComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild( routes ),
    MatGridListModule,
    MatChipsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatToolbarModule,    
    MatTooltipModule,    
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [
    PatientAddComponent,
    PatientEditComponent,
    PatientSelectComponent,
    PatientViewComponent,
    PatientVisitAddComponent,
    PatientVisitEditComponent,
    PatientEditDialogDeleteComponent, 
    PatientVisitEditDialogDeleteComponent,
    PatientVisitViewComponent,
    PatientListAllComponent
  ]
})
export class PatientModule { }
