import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Visit } from '@app/models/visit';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BaseComponent } from '@app/base.component';

@Component({
  selector: 'app-patient-visit-list',
  templateUrl: './patient-visit-list.component.html',
  styleUrls: ['./patient-visit-list.component.css']
})
export class PatientVisitListComponent extends BaseComponent implements OnInit {
  
  @Input('visits') visits: Visit[];
  @Input('hasButtonAdd') hasButtonAdd: Boolean = false;
  @Output('onAddVisit') onAddVisit: EventEmitter<any> = new EventEmitter<any>(); 
  @Output('onViewVisit') onViewVisit: EventEmitter<any> = new EventEmitter<any>();
  
  columnsToDisplay: string[];
  cptColor: ThemePalette = "warn";
  icdColor: ThemePalette = "accent";
  isHandset$: Observable<boolean> = this.breakpointObserver.observe( Breakpoints.Handset )
    .pipe(
      map( result => result.matches ),
      shareReplay()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private _snackBar: MatSnackBar
  ) {
    super();
  }

  // initialize code
  ngOnInit(): void {
    this.isHandset$
    .pipe( takeUntil( this.appUnsubscribe ) )
    .subscribe( result => {
      if( result )
      {
        this.columnsToDisplay = ['action_select', 'physician', 'appointment_date'];
      } else {
        this.columnsToDisplay = ['action_select', 'physician', 'appointment_date', 'cpt_codes', 'icd10_codes', 'notes'];
      }
    });
  }

  // on changes
  ngOnChanges(): void {
  }

  // what to do when add visit button is clicked
  handleAddVisit(): void {
    this.onAddVisit.emit();
  }

  handleViewVisit( element ): void {
    this.onViewVisit.emit( element );
  }

  trackByChip(  index: number, item: any ): string {
    return item.code;
  } 

  openSnackBar(toolTip: string) {
    this._snackBar.open(toolTip, 'Ok', {
      duration: 3000
    });
  }

}
